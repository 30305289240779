import { useSelector } from 'react-redux';
import { useState } from 'react';
import './promotions.scss';
import axios from 'axios';
import {baseUrl} from '../../config.json';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import Twitter from './twitter.svg';
import Facebook from './facebook.svg';
import Whatsapp from './whatsapp.svg';
import { Flex, Img } from '@chakra-ui/react';

const config = {
    headers: {
       "Authorization": `Bearer ${localStorage.WEHAUL_CUSTOMER_TOKEN}`
       }
}

const Promotions = () => {
    const [editModal, setEditModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingPage, setLoadingPage] = useState(true);
    const [copied, setCopied] = useState(false);
    const [deets, setDeets] = useState(null);
    const [newCode, setNewCode] = useState();
    const [refresh, setRefresh] = useState("")

    const currentUser = useSelector(({ userData }) => userData.currentUser);
    const { referralCode, id, walletBalance } = currentUser.data;

    const handleCopy = async () => {
        navigator.clipboard.writeText(deets?.referralCode)
        await setCopied(true)
        setTimeout(() => {
            console.log('This will run after 1 second!')
            setCopied(false)
          }, 1000);
    }

    useEffect(() => {
        // try {
        //     const {data} = axios.get(`${baseUrl}/referrals/${id}`, config)
            
        // } catch(error) {
        //     console.log(error?.response?.data?.message)
        //     toast.error(error?.response?.data?.message)
        // }
        const res = axios.get(`${baseUrl}/referrals/${id}`, config)
        // .then(res => console.log(res?.data?.data))
        .then(res => setDeets(res?.data?.data))
        .then(res => setLoadingPage(false))
        .catch(err => console.log(err))
    }, [refresh]);

    const datax = {
        "referralCode": newCode,
        userClass: "customer"
    }

    const customizeCode = async e => {
        e.preventDefault();
        setLoading(true)

        try {
            const {data} = await axios.put(`${baseUrl}/referrals/update/${deets._id}`, datax, config)
            console.log(data)
            toast.success(data?.message)
            setEditModal(false)
            setRefresh(Math.random())
            setLoading(false)
        } catch (error) {
            console.log(error?.response?.data?.message)
            toast.error(error?.response?.data?.message)
            setLoading(false)
        }


    }

    return (
        <div className="promotions">
            <div className="share">Share & Earn</div>
            <p className="info">Share your referral code with friends and earn ₦500 when they sign up and complete a trip with your promo code!</p>
            <div className="earnings">
                <p className="val">₦{walletBalance?.toLocaleString()}</p>
                <p className="label">Referral Earnings</p>
            </div>
            {!loadingPage && <>
            <div className="btn">
                <button onClick={() => setEditModal(prev => !prev)}>{editModal ? "Close" : "Edit"}</button>
                {editModal && 
                <form onSubmit={customizeCode} className="edit-box">
                    <input minLength={2} maxLength={15} onChange={e => setNewCode(e.target.value.replace(/\s/g, "").toUpperCase())} type="text" placeholder='Enter Personalized Code'/><br />
                    <button type='submit'>{loading ? "Cooking..." : "Customize"}</button>
                </form>
                }
            </div>
            <div onClick={handleCopy} className="promo-box">
                <div className="top">
                    <p className="txt">Tap to Copy<i className="fas fa-copy"></i></p>
                    {copied && <p className="tooltip">Copied <i className="fas fa-check"></i></p>}
                </div>
                <p className="code"><p className='line'></p>{deets?.referralCode}<p className='line'></p></p>
            </div>
            <ul className='icons'>
                <p className="lab">Share via</p>
                <Flex justifyContent="center">
                    <a href={"whatsapp://send?text=Hi, Please use my referral code "+ deets?.referralCode + " to sign up on Wehaul247 to enjoy moving stuff around Lagos at unbeatable prices. https://wehaul247.com/"} data-action="share/whatsapp/share"><Img margin="0 auto" src={Whatsapp} alt="Share Page on Whatsapp" /></a>
                    {/* <a href="https://www.facebook.com/sharer.php?..." target="blank" rel="noopener noreferrer"><img src={Facebook} alt="Share Page on Facebook" /></a> */}
                    <a href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fwehaul247.com%2F&text=Hi,+Please+use+my+referral+link+to+sign+up+on+Wehaul247+to+enjoy+moving+stuff+around+lagos+at+unbeatable+prices." target="blank" rel="noopener noreferrer"><Img margin="0 auto" src={Twitter} alt="Share Page on Twitter" /></a>
                </Flex>
            </ul>
            <p className="terms">Terms & Conditions Apply</p>
            </>}
        </div>
    )
}

export default Promotions;