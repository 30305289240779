import React, { useState } from 'react';
import BikeRequest from '../bike-request/bikeRequest';
import OtherVehiclesRequest from '../otherVehicles-request/otherVehiclesRequest';
import TruckRequest from '../truck-request/truckRequest';
import './requestForms.scss';
import { useQuery } from 'react-query';
import { getAllRoutes, getAllVehicles } from '../../../services/routeServices';

const RequestForms = () => {
    const [page, setPage] = useState('vehicles');

    const { data, error, isLoading } = useQuery('getAllRoutes', getAllRoutes)
    if(isLoading) {
      console.log('loading all routes')
    } else if(data) {
        console.log('gotten all routes', data.data.data)
    } else {
        console.log(error)
    }

    return ( 
        <div className="request-forms">
           <h2>Request New Trip</h2>
           
           {/* <div className="nav">
               <p onClick={() => setPage('bike')} id='first' className={page === 'bike' ? 'active nav-items' : 'nav-items'}>Bike</p>
               <p onClick={() => setPage('vehicles')}  className={page === 'vehicles' ? 'active nav-items' : 'nav-items'}>Vehicles</p>
               <p onClick={() => setPage('truck')} id='third'  className={page === 'truck' ? 'active nav-items' : 'nav-items'}>Trucks</p>
           </div> */}
           {/* {page === 'bike' && <BikeRequest routes={data?.data?.data} />} */}
           <OtherVehiclesRequest routes={data?.data?.data} />
           {/* {page === 'truck' && <TruckRequest routes={data?.data?.data} />} */}
        </div>
     );
}

 
export default RequestForms;