import React from "react";
import "./newModal.scss";
import ButtonLoader from "../buttonLoader/buttonLoader";
import { useState } from "react";
import Payment from "../payments/payment";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import {baseUrl} from "../../config.json";
import CheckoutForm from "../payments/stripeCheckout/stripeCheckout";

const config = {
  headers: {
     "Authorization": `Bearer ${localStorage.WEHAUL_CUSTOMER_TOKEN}`
     }
}

const NewModal = ({setShowDeleteModal, loading, handleStripe, text, loading2, requestFunction, type, depositAmount, driverId, userClass, reqId}) => {
  const [pType, setPType] = useState("");
  const [agree, setAgree] = useState(false);

  const [coupon, setCoupon] = useState("");
  const [couponVal, setCouponVal] = useState(false);
  const [couponValue, setCouponValue] = useState(0);
  const [cload, setCLoad] = useState(false);

  

  const currentUser = useSelector(({ userData }) => userData.currentUser);

  const { walletBalance, _id: id, email, discount, country } = currentUser.data;

  function Button() {
       if(pType === "paystack" && agree) {
          return <Payment driverId={driverId} reqId={reqId} userClass={userClass} userId={id} userEmail={email} depositAmount={Math.ceil(depositAmount - ((discount?.promoDiscount/100) * depositAmount))} />
      } else if(pType === "stripe" && agree) {
          return <CheckoutForm />
      } else if(pType === "earnings" && agree) {
          return <button onClick={requestFunction} type="submit">{loading && <ButtonLoader />}CONTINUE</button>
      } else return <button type="submit" disabled>CONTINUE</button>
  }

 

  const processData = async (e) => {
        e.preventDefault()
        if(pType === "paystack") {

        } else if(pType === "earnings") {

        } else return
  }

  const datax = {
    "couponCode": coupon.toUpperCase()
  }

  const applyCoupon = async () => {
      setCLoad(true)
      try {
        const res = await axios.post(`${baseUrl}/coupon/use`, datax, config)
        console.log(res)
        setCouponValue(res?.data?.data?.amount)
        toast.success(res?.data?.message)
        setCouponVal(true)
        setCLoad(false)
      } catch (error) {
        toast.success(error?.response?.data?.message);
        console.log(error?.response?.data?.message);
        setCLoad(false)
      }
  }


  return (
    <div className="new-modal">
      <div className="wrapper">
        <div className="modal">
          <div className="top-head">
            <i className="fas fa-times" onClick={() => setShowDeleteModal(false)}></i>
            <div className="left">
              <p className="title">Payment Method</p>
              <p className="para">Select your preferred payment option</p>
            </div>
            <div className="right">
              <p className="label">Pay</p>
              {discount?.promoStatus ? <>
              <p className="value">₦{Math.ceil(depositAmount - ((discount?.promoDiscount/100) * depositAmount)).toLocaleString()}</p>
              <strike><p className="value" style={{fontSize: ".9rem", opacity: ".7"}}>₦{depositAmount?.toLocaleString()}</p></strike>
              {couponVal && <strike><p className="value" style={{fontSize: ".9rem", opacity: ".7"}}>₦{depositAmount?.toLocaleString()}</p></strike>}
              </>
              :
              <>
              <p className="value">₦{Math.ceil(depositAmount - ((couponValue/100) * depositAmount)).toLocaleString()}</p>
              {couponVal && <strike><p className="value" style={{fontSize: ".9rem", opacity: ".7"}}>₦{depositAmount?.toLocaleString()}</p></strike>}
              </>
              }
             
            </div>
          </div>
            <div className="coupon input">
              <input style={{textTransform: "uppercase"}} placeholder="Enter coupon code" type="text" onChange={e => setCoupon(e.target.value)} />
              <button onClick={applyCoupon}>{cload && <ButtonLoader />}APPLY</button>
            </div>
          <form onSubmit={processData} className="body">
            <div className="input">
              <input onClick={() => setPType("earnings")} name="type" type="radio" />
              <div className="diff">
                <label>Pay from referral earnings balance</label>{" "}
                <p className="spanx">₦{walletBalance?.toLocaleString()}</p>
              </div>
            </div>
           {country === "nigeria" || country === "ghana" ? <div className="input">
              <input onClick={() => setPType("paystack")} name="type" type="radio" />
              <label>Pay with Paystack</label>
            </div>
            :
            <div className="input">
              <input onClick={() => setPType("stripe")} name="type" type="radio" />
              <label>Pay with Stripe</label>
            </div>}
            <br />
            <div className="bodyx">
                            <p className="text">This action is permanent, Do you want to proceed?</p>
                            {type === "assign" && <>
                            <p className='ctext'>Cancelling this request later may result in you being charged a small amount for driver compensation. Do you agree?</p>
                            <br />
                            <input type="checkbox" name='agree' required onChange={() => setAgree(prev => !prev)} />
                            <label htmlFor="agree" className="agree">I Agree</label>
                            </>}
                            {/* <div className="btns">
                                <button onClick={() => setShowDeleteModal(false)} className="cancel">CANCEL</button>
                                <button className='payx'>PAY NOW</button> */}
                                {/* <Payment driverId={driverId} reqId={reqId} userClass={userClass} userId={_id} userEmail={email} depositAmount={depositAmount}  /> */}
                                {/* <button onClick={requestFunction} className="continue">{loading ? <ButtonLoader /> : text}</button> */}
                            {/* </div> */}
                        </div>
            <div className="btn">
              <Button />
            </div>
          </form>
         
        </div>
      </div>
      
    </div>
  );
};

export default NewModal;
