import React from 'react';
import './deleteModal.scss';
import ButtonLoader from '../buttonLoader/buttonLoader';
import { useState } from 'react';
import Payment from '../payments/payment';
import { useSelector } from 'react-redux';
import NewModal from '../newModal/newModal';


const DeleteModal = ({setShowDeleteModal, loading, text, requestFunction, type, depositAmount, driverId, userClass, reqId}) => {
    const [agree, setAgree] = useState(false);
    const [options, setOptions] = useState(false);

    // const [modal1, setModal1] = useState("");
    // const [modal2, setModal2] = useState("out");

    const currentUser = useSelector(({ userData }) => userData.currentUser);

    const { walletBalance } = currentUser.data

    const handleSwitch = () => {
    }

    return ( 
        <div className="delete-modal">
            <div className="wrapper">
                <div className="modaly">
                        <div className="top-head">
                            <i className="fas fa-exclamation-triangle"></i><p className="title">Are you sure ?</p>
                        </div>
                        <div className="body">
                            <p className="text">This action is permanent, Do you want to proceed?</p>
                            <br />
                            {type === "assign" && <>
                            <p className='ctext'>Cancelling this request later may result in you being charged a small amount for driver compensation. Do you agree?</p>
                            <br />
                            <input type="checkbox" name='agree' required onChange={() => setAgree(prev => !prev)} />
                            <label htmlFor="agree">I Agree</label>
                            </>}
                            <div className="btns">
                                <button onClick={() => setShowDeleteModal(false)} className="cancel">CANCEL</button>
                                <button onClick={requestFunction} className='payx'>CONTINUE</button>
                                {options && <NewModal setOptions={setOptions} />}
                                {/* <Payment driverId={driverId} reqId={reqId} userClass={userClass} userId={_id} userEmail={email} depositAmount={depositAmount}  /> */}
                                {/* <button onClick={requestFunction} className="continue">{loading ? <ButtonLoader /> : text}</button> */}
                            </div>
                        </div>
                </div>

                <div className="modalx">
                <div className="top-head">
                    <i className="fas fa-times"></i>
                    <div className="left">
                    <p className="title">Payment Method</p>
                    <p className="para">Select your preferred payment option</p>
                    </div>
                    <div className="right">
                    <p className="label">Pay</p>
                    <p className="value">₦{"2,000"}</p>
                    </div>
                </div>
                <form className="body">
                    <div className="input">
                    <input name="type" type="radio" />
                    <div className="diff">
                        <label>Pay from earnings balance</label>{" "}
                        <p className="spanx">₦{"2,760"}</p>
                    </div>
                    </div>
                    <div className="input">
                    <input name="type" type="radio" />
                    <label>Pay with paystack</label>
                    </div>
                    <div className="btn">
                    <button type="submit">CONTINUE</button>
                    </div>
                </form>
                </div>
            </div>
        </div>
     );
}
 
export default DeleteModal;