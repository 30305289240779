import React from 'react';
import './deposits.scss';
import Depositslist from '../../../static/deposits';
import EmptyState from '../../emptyState/emptyState';

const Deposits = ({data}) => {
    return ( 
        <div className="deposits">
            {data?.length === 0 ? <EmptyState /> : 
                <div className="cover">
                <p className='top-title'>Recent Transactions</p>
                <table>
                    <tr className='label-row'>
                        <th>S/N</th>
                        <th>Amount</th>
                        <th>Date</th>
                        <th>Type</th>
                        <th>Reference</th>
                    </tr>
                        {data?.map(({ amount, date, transType, source, reference }, index) => {
                            return (
                                <tr className='content-row' key={index}>
                                   <td>{index + 1}</td>
                                   <td>{amount}</td>
                                   <td>{date.slice(0,10)}</td>
                                   <td>{transType}</td>
                                   <td>{reference}</td>
                                </tr>
                            )
                        })}
                </table>
                </div>
            }
        </div>
     );
}
 
export default Deposits;