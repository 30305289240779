import React, { useState } from "react";
import ButtonLoader from "../../components/buttonLoader/buttonLoader";
import "./passwordReset.scss";
import {Link} from 'react-router-dom';
import Logo from '../../assets/logo.svg'
import axios from "axios";
import {baseUrl, herokuBaseUrl} from '../../config.json';
import { toast } from "react-toastify";

const PasswordReset = () => {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);

    const datax = {
        email
    }

    const reset = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {
            const {data} = await axios.post(`${herokuBaseUrl}/auth/customer/forgot-password`, datax)
            console.log(data)
            toast.success(data.message)
            setLoading(false)
        } catch (error) {
            toast.error(error?.response?.data?.message)
            setLoading(false)
        }
    }

  return (
    <div className="password-reset">
      <div className="container">
        <div className="left"></div>
        <div className="right">
          <form onSubmit={reset}>
            <img id="logo" src={Logo} alt="wehaulogo" />
            <h3>Forgot Your Password?</h3>
            <input
              type="email"
              placeholder="Enter Email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />{" "}
            <br />
            <button type="submit">{loading && <ButtonLoader />} Send Reset Link</button>
            <Link to="/" style={{ textDecoration: "none" }}>
              <p className="forgot">
                Back to Login?{" "}
                <span style={{ color: "#113E82" }} className="forgot">
                  Login
                </span>
              </p>
            </Link>
            {/* <div className="google"><img style={{transform: 'translateY(7px)', marginRight: '8px'}} src={Google} alt="" /><span>Sign in with google</span></div> */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
