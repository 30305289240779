import { baseUrl, herokuBaseUrl } from '../config.json';
import axios from 'axios';

const config = {
    headers: {
       "Authorization": `Bearer ${localStorage.WEHAUL_CUSTOMER_TOKEN}`
       }
 }

export async function getTripAnalytics() {
    return axios.get(`${herokuBaseUrl}/customer/trip-analytics`, config);
}

export async function getAllTrips() {
    return axios.get(`${herokuBaseUrl}/customer/trip/retrieve-trips`, config)
}

export async function getTripDetails(id) {
    return axios.get(`${herokuBaseUrl}/customer/trip/${id}/trip-details`, config)
}