import React, { useState } from 'react';
import Payment from '../../payments/payment';
import { useSelector } from "react-redux";
import './withdrawModal.scss';
import ButtonLoader from '../../buttonLoader/buttonLoader';
import {baseUrl} from '../../../config.json';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';

const WithdrawModal = ({setShowWithdrawModal}) => {
    const [withdrawAmount, setWithdrawAmount] = useState('');
    const [reason, setReason] = useState('');
    const[loading, setLoading] = useState(false);

    const history = useHistory();

    const currentUser = useSelector(({ userData }) => userData.currentUser);
    console.log(currentUser.data);

    const { email, _id } = currentUser.data

    const config = {
        headers: {
           "Authorization": `Bearer ${localStorage.WEHAUL_CUSTOMER_TOKEN}`
           }
     }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const data = {
            "amount": withdrawAmount,
            "description": reason
        }

        try {
            const res = await axios.post(`${baseUrl}/customer/request-payout`, data, config)
            console.log(res.data)
            setLoading(false)
            toast.success(res.data.message, {
                position: toast.POSITION.TOP_CENTER
            })
            window.location.reload()
        } catch (error) {
            console.log(error.response.data.message)
            setLoading(false)
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_CENTER
            })
        }
    }

    return ( 
        <div className="amount-modal">
            <div className="wrapper">
                <div className="modal">
                <i onClick={() => setShowWithdrawModal(false)} className="fas fa-times"></i>
                    <div className="top-head">
                        <p className="title">Amount to Withdraw</p>
                    </div>
                    <div className="body">
                        <form onSubmit={handleSubmit}>
                            <input required value={withdrawAmount} onChange={e => setWithdrawAmount(e.target.value.trim())} type="tel" placeholder="Enter Amount" />
                            <label htmlFor="reason">Reason For Withdrawal <span>(optional)</span></label>
                            <select required onChange={e => setReason(e.target.value)} name="reason" id="reason">
                                <option></option>
                                <option value="Over-funded my account">Over-funded my account</option>
                                <option value="Changed my mind about the request">Changed my mind about the request</option>
                                <option value="Found a better alternative">Found a better alternative</option>
                                <option value="I'd rather not say">I'd rather not say</option>
                                <option value="No reason">No reason</option>
                            </select>
                            <div className="btn">
                                <button>{loading && <ButtonLoader />}Withdraw Funds</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default WithdrawModal;