import React, { useState } from "react";
import "./allRequests.scss";
import { useQuery } from "react-query";
import { cancelRequest, getAllRequests } from "../../../../services/requestServices";
import DeleteModal from "../../../deleteModal/deleteModal";
import EmptyState from "../../../emptyState/emptyState";
import { toast } from "react-toastify";
import axios from "axios";
import { baseUrl } from '../../../../config.json';
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import RequestView from "../request-view/requestView";

const AllRequests = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [clickedId, setClickedId] = useState("");
  const [requestViewShown, setRequestViewShown] = useState(false);
  const [requestId, setRequestId] = useState("");

  const history = useHistory();

  const { data, error, isLoading } = useQuery("getTrips", getAllRequests);
  if (isLoading) {
    console.log("data is loading");
  } else if (data) {
    console.log("getRequests", data.data.data);
  } else {
    console.log(error);
  }

  const config = {
    headers: {
       "Authorization": `Bearer ${localStorage.WEHAUL_CUSTOMER_TOKEN}`
       }
  }

  const getRequestId = async (id) => {
    console.log(id);
    await setRequestId(id);
    setRequestViewShown(true);
  };


  const handleCancel = (id) => {
     setClickedId(id)
     setShowDeleteModal(true)
  }

  const formData = {
    lowerBoundPrice: 10000
  }

  const cancelRequest = async () => {
    try {
       const res = await axios.put(`${baseUrl}/customer/request/${clickedId}/cancel-request`, formData, config);
       toast.success(res.data.message, {
        position: toast.POSITION.TOP_CENTER
     })
         console.log(res.data.message)
        //  history.push('/dashboard/request')
       window.location.reload()
     } catch(error) {
        if(error) {
           // console.log('error', error.response.data.message)
           toast.error(error.response.data.message, {
              position: toast.POSITION.TOP_CENTER
           })
        }
     }
 }


  return (
    <div className="all-requests">
      {data?.data?.data.length === 0 ? (
        <EmptyState />
      ) : (
        <table>
          <tr className="label-row">
            <th>Pick-up</th>
            <th>Drop-off</th>
            <th>Vehicle</th>
            <th>Receiver</th>
            <th>Pickup Date</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
          {data?.data?.data.map(
            (
              {
                pickUpAddress,
                destinationAddress,
                vehicleType,
                receiverDetails,
                pickUpDate,
                requestStatus,
                responseCount,
                estPrice,
                qualifiedBikeRiders,
                _id: id
              },
              index
            ) => {
              return (
                <tr className="content-row" key={index}>
                  <td className="lefty pre">{pickUpAddress}</td>
                  <td className="lefty">{destinationAddress}</td>
                  <td>{vehicleType}</td>
                  <td>{receiverDetails.name}</td>
                  <td>{pickUpDate}</td>
                  {requestStatus === "active" && (
                    <td>
                      <p className="active-status">{requestStatus}</p>
                    </td>
                  )}
                  {requestStatus === "rejected" && (
                    <td>
                      <p className="cancelled-status">{requestStatus}</p>
                    </td>
                  )}
                  {requestStatus === "cancelled" && (
                    <td>
                      <p className="cancelled-status">{requestStatus}</p>
                    </td>
                  )}
                  <td>
                    <p onClick={() => getRequestId(id)} style={{ backgroundColor: "#113E82", color: "#fff", padding: "5px", borderRadius: "6px", marginBottom: "7px" }}>View</p>
                    {vehicleType === 'bike' && requestStatus !== 'cancelled' && <Link to={{pathname: "/dashboard/request/bike-drivers",state: {reqId: id, reqData: qualifiedBikeRiders}}}><div><i style={{ backgroundColor: "#FFCE45" }} className="fas fa-biking"></i></div></Link>}
                    {vehicleType !== 'bike' && <div onClick={() => history.push('/dashboard/request/other-drivers', {reqId: id})}><p style={{ backgroundColor: '#FFCE45', color: "#fff", padding: "5px", borderRadius: "6px", marginBottom: "7px" }}>Responses <span style={{padding: "2px 7px", borderRadius: "50%", backgroundColor: "#113E82"}}>{responseCount}</span></p></div>}
                    {/* <div>
                      <i
                        style={{ backgroundColor: "#37AB78" }}
                        className="far fa-comment-dots"
                      ></i>
                    </div> */}
                    {vehicleType !== 'bike' ?
                    requestStatus === 'active' && 
                    <div onClick={() => handleCancel(id)}>
                      <p
                        style={{ backgroundColor: "#FF1B00", color: "#fff", padding: "5px", borderRadius: "6px"  }}
                      >Cancel</p>
                    </div> : null}
                  </td>
                </tr>
              );
            }
          )}
        </table>
      )}
      {showDeleteModal && (
        <DeleteModal
          text="CONTINUE"
          setShowDeleteModal={setShowDeleteModal}
          requestFunction={cancelRequest}
        />
      )}
      {requestViewShown && <RequestView requestId={requestId} setRequestViewShown={setRequestViewShown} />}
    </div>
  );
};

export default AllRequests;
