import React from "react";
import "./requestView.scss";
import Avatar from "../../../header/avatar.svg";
import axios from "axios";
import { baseUrl } from '../../../../config.json'
import RippleLoader from "../../../rippleLoader/rippleLoader";

class RequestView extends React.Component {
    state = {
        requestId: this.props.requestId,
        setRequestViewShown: this.props.setRequestViewShown,
        loading: true,
        requestData: {
            acceptedBy: '',
            request: '',
            tripFee: '',
            requestStatus: ''
        }
    }

    componentDidMount() {
        const config = {
            headers: {
               "Authorization": `Bearer ${localStorage.WEHAUL_CUSTOMER_TOKEN}`
               }
         }
         axios.get(`${baseUrl}/customer/request/${this.state.requestId}/request-details`, config)
        //  .then(res => console.log(res?.data))
         .then(res => this.setState({requestData: res?.data?.data, loading: false}))
         .catch(err => console.log(err))
    }
  render() {
          const {requestTitle, goodsImage, goodsQuantity, goodsWeight, isExpress, loaders, goodsDescription, createdBy, vehicleType, request, tripFee, FeeWithCommission, requestStatus, pickUpDate, pickUpAddress, destinationAddress, pickUpRoute, destinationRoute, receiverDetails} = this.state.requestData
  
    return (
      <div className="trip-view-page">
          {this.state.loading ? <RippleLoader /> : 
          <div className="vee">
              <p className="top-head">Trips</p>
          <div className="trip-view">
            <div className="top-area">
              <div className="user-deet">
                <div className="left-side">
                  <div className="img" style={{backgroundImage: `url(${createdBy.profilePicture})`}}></div>
                  <div className="name-date">
                    <p className="driver-name">
                      <i className="far fa-user-circle"></i>{createdBy.name}
                    </p>
                    <p className="date">
                      <i className="far fa-clock"></i>{pickUpDate}
                    </p>
                  </div>
                </div>
                <div className="right-side">
                  <p className="status-label">status</p>
                  {requestStatus === 'pending' && <p style={{backgroundColor: '#FFBB00'}} className="status">{requestStatus}</p>}
                  {requestStatus === 'active' && <p style={{backgroundColor: '#40D192'}} className="status">{requestStatus}</p>}
                  {requestStatus === 'on-going' && <p style={{backgroundColor: '#113E82'}} className="status">{requestStatus}</p>}
                  {requestStatus === 'cancelled' && <p style={{backgroundColor: '#FF1B00'}} className="status">{requestStatus}</p>}
                  
                </div>
              </div>
              <p className="title">Request Information</p>
            </div>
  
            <div className="middle-area">
              <div className="req-info">
                <div className="tag">
                  <p>Pickup Point (Origin)</p>
                  <p>Destination</p>
                  <p>Vehicle Type</p>
                  <p>Pickup route</p>
                  <p>Destination route</p>
                  <p>Receiver's Name</p>
                  <p>Pickup Date</p>
                  <p>Goods Quantity</p>
                  <p>Goods Weight</p>
                  <p>Express Delivery</p>
                  <p>Loaders</p>
                  <p>Request Title</p>
                  <p>Request Description</p>
                  <p>Goods Image</p>
                </div>
                <div className="val">
                  <p>
                    <i className="fas fa-map-marker-alt"></i>{pickUpAddress}
                  </p>
                  <p>
                    <i className="fas fa-location-arrow"></i>{destinationAddress}
                  </p>
                  <p>
                    {vehicleType === 'truck' && <i className="fas fa-truck"></i>}
                    {vehicleType === 'van' && <i className="fas fa-truck"></i>}
                    {vehicleType === 'lorry' && <i className="fas fa-truck"></i>}
                    {vehicleType === 'bike' && <i className="fas fa-biking"></i>}
                    <span>{vehicleType}</span>
                  </p>
                  <p>
                    <i className="far fa-calendar-alt"></i>{pickUpRoute}
                  </p>
                  <p>
                    <i className="fas fa-map-marker-alt"></i>{destinationRoute}
                  </p>
                  <p>
                    <i className="fas fa-user"></i>{receiverDetails?.name}
                  </p>
                  <p>
                    <i className="far fa-calendar-alt"></i>{pickUpDate}
                  </p>
                  <p>
                    <i className="far fa-circle"></i>{goodsQuantity} Units
                  </p>
                  <p>
                    <i className="far fa-circle"></i>{goodsWeight} Kg
                  </p>
                  <p>
                    <i className="far fa-circle"></i>{isExpress ? "Yes" : " No"}
                  </p>
                  <p>
                    <i className="far fa-circle"></i>{loaders}
                  </p>
                  {/* <p>
                    <i className="fas fa-money-bill"></i>{FeeWithCommission}
                  </p> */}
                  <p id="light">
                    <i
                      style={{ fontWeight: "600" }}
                      className="far fa-file-alt"
                    ></i>
                   {requestTitle}
                  </p>
                  <p id="light">
                    <i
                      style={{ fontWeight: "600" }}
                      className="far fa-file-alt"
                    ></i>
                   {goodsDescription}
                  </p>
                  <div className="img">
                    
                  </div>
                </div>
              </div>
              {/* <p className="title">Driver's Information</p> */}
            </div>
  
            <div className="bottom-area">
              {/* <div className="driver-info">
                <div className="tag">
                  <p>Driver Name</p>
                  <p>Phone Number</p>
                </div>
                <div className="val">
                  <p>
                    <i className="fas fa-user"></i>{createdBy.name}
                  </p>
                  <p>
                    <i className="fas fa-mobile-alt"></i>{createdBy.phoneNumber}
                  </p>
                </div>
              </div> */}
              <button onClick={() => this.state.setRequestViewShown(false)}>Close</button>
            </div>
          </div>
          </div>
          
          }
        
      </div>
    );
  }
}

export default RequestView;
