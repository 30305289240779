import React from "react";
import "./tripsChart.scss";
import { Line } from "react-chartjs-2";
import {useQuery} from 'react-query';
import { getTripAnalytics } from '../../../services/tripServices'



const TripsChart = () => {

  const { data, error, isLoading } = useQuery('getTripAnalytics', getTripAnalytics)
    if(isLoading) {
        console.log('data is loading')
    }else if(data) {
        console.log('getTripAnalytics', data.data.data)
    } else {
        console.log(error)
    }

    const state = {
      labels: ["January", "February", "March", "April", "May"],
      datasets: [
        {
          label: "Active Trips",
          fill: false,
          lineTension: 0.5,
          backgroundColor: "blue",
          borderColor: "rgba(0,0,0,1)",
          borderWidth: 1,
          data: [data?.data?.data?.onGoingTrips],
        },
        {
          label: "Completed Trips",
          fill: false,
          lineTension: 0.5,
          backgroundColor: "green",
          borderColor: "rgba(0,0,0,1)",
          borderWidth: 1,
          data: [data?.data?.data?.completedTrips],
        },
        {
          label: "Cancelled Trips",
          fill: false,
          lineTension: 0.5,
          backgroundColor: "red",
          borderColor: "rgba(0,0,0,1)",
          borderWidth: 1,
          data: [data?.data?.data?.cancelledTrips],
        },
      ],
    };
    
  return (
    <div className="trips-chart">
      <Line
        data={state}
        height={167}
        className="chart"
        options={{
          title: {
            display: true,
            text: "Average Rainfall per month",
            fontSize: 20,
          },
          legend: {
            display: true,
            position: "right"
          },
        }}
      />
    </div>
  );
};

export default TripsChart;
