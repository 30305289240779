// import "./navbar.scss";
import Logo from "./wehaulogo2.png";
import { Link } from "react-router-dom";
import { useState } from "react";
// import Reglog from "../../pages/reglogModal/reglog";
// import Sidebar from "../sidebar/sidebar";
import { Box, Button, Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Flex, FormControl, Heading, HStack, Image, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Text, useDisclosure } from "@chakra-ui/react";
import { toast } from "react-toastify";
import axios from "axios";
// import { baseUrl } from "../../config";
// import { CheckCircleIcon } from "@chakra-ui/icons";



const Navbar = () => {
  const [navOpen, setNavOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [expansible, setExpansible] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [reqId, setReqId] = useState("");
  const [historyx, setHistoryx] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();


  const openNav = () => {
    setNavOpen(true);
  };
  const closeNav = () => {
    setNavOpen(false);
  };




  return (
      <>
      <Flex padding={"1.5rem"} justifyContent={"space-between"} alignItems="center" bg="#1A59B9"> 
        <Flex alignItems="center" gap="2rem">
          <Image onClick={() => window.open('https://wehaul247.com', "_blank") || window.location.replace('https://wehaul247.com')} _hover={{color: "#F2D399"}} transition="250ms ease" cursor={"pointer"} mr="2rem" w={{base: "20%", md: "5%"}} src={Logo} />
          <Text display={{base: "none", md: "flex"}}  onClick={() => window.open('https://wehaul247.com', "_blank") || window.location.replace('https://wehaul247.com')} _hover={{color: "#F2D399"}} transition="250ms ease" cursor={"pointer"} color={"#FFF"} fontSize={".9rem"} fontWeight={"600"}>Home</Text>
          <Text display={{base: "none", md: "flex"}} _hover={{color: "#F2D399"}} transition="250ms ease" cursor={"pointer"} color={"#FFF"} fontSize={".9rem"} fontWeight={"600"}>Partner With Us</Text>
          <Text display={{base: "none", md: "flex"}} onClick={() => window.open('https://blog.wehaul247.com', "_blank") || window.location.replace('https://blog.wehaul247.com')} _hover={{color: "#F2D399"}} transition="250ms ease" cursor={"pointer"} color={"#FFF"} fontSize={".9rem"} fontWeight={"600"}>Blog</Text>
        </Flex>
        <Flex alignItems={"center"} gap="1rem">
          <Button display={{base: "none", md: "flex"}} onClick={() => window.open('https://wehaul247.com/driver', "_blank") || window.location.replace('https://wehaul247.com/driver')} bg="#F2D399" fontWeight={"500"} color="#123870" fontSize=".85rem">Become a driver</Button>
          <Text onClick={() => window.open('https://customer.wehaul247.com', "_blank") || window.location.replace('https://customer.wehaul247.com')} _hover={{color: "#F2D399"}} transition="250ms ease" color="#fff" textDecoration={"underline"} fontSize={".9rem"}>Register/Login</Text>
        </Flex>
      </Flex>
      {/* <HStack height={"90px"} padding="1rem 2rem">
        <Flex gap={"2rem"} alignItems={"center"}>
          <Image cursor={"pointer"} mr="2rem" w={{base: "20%", md: "9%"}} src={Logo} />
          <Link to="/"><Text className="right" cursor={"pointer"} color={"#F2A007"} fontSize={".9rem"} fontWeight={"600"}>Home</Text></Link>
          <a href="https://blog.wehaul247.com" target="_blank" rel="noopener noreferrer"><Text className="right" cursor={"pointer"} fontSize={".9rem"} fontWeight={"600"}>Blog</Text></a>
        </Flex>

        <Flex fontSize={".9rem"} gap={"2rem"} alignItems={"center"}>
          <Text className="right" whiteSpace={"nowrap"} fontWeight={"600"}>Call us at:  01-888-6060</Text>
          <Text className="right" whiteSpace={"nowrap"} fontWeight={"600"}>0916-0000-687</Text>
          <Link className="right" onClick={() => setExpansible(false)} to="/driver" style={{ textDecoration: "none" }}><Button bg="#F2D399" fontSize={".9rem"} fontWeight="600">Become a Driver</Button></Link>
          <Text className="right" cursor={"pointer"} fontWeight={"600"} onClick={() => setShowModal(true)}>Login/Register</Text>
        </Flex>

        <div className="bar">
          {navOpen ? (
              <i onClick={closeNav} class="fas fa-times"></i>
            ) : (
              <i onClick={openNav} class="fas fa-bars"></i>
          )}
        </div>
      </HStack> */}



     

      {/* {showModal && <Reglog setRegLogModal={setShowModal} />} */}
      
      {/* <Sidebar
        setShowModal={setShowModal}
        setNavOpen={setNavOpen}
        navOpen={navOpen}
        track={onOpen}
      /> */}


        {/* <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent margin={"0 .5rem"}>
            <ModalHeader>Track Request</ModalHeader>
            <ModalCloseButton _focus={{boxShadow: "none"}} />
            <ModalBody>
                <FormControl>
                    <Input onChange={e => setReqId(e.target.value.trim())} textTransform="uppercase" placeholder="Enter Request ID" />
                </FormControl>
            </ModalBody>

            <ModalFooter>
                <Button variant='ghost' mr={3} onClick={onClose}>
                Close
                </Button>
                <Button type="sumbit" colorScheme='blue' w={130} onClick={trackRequest}>{loading2 ? <Spinner /> : "Find Request"}</Button>
            </ModalFooter>
            </ModalContent>
        </Modal> */}

        {/* <Drawer
            isOpen={isOpen2}
            placement='right'
            onClose={onClose2}
            size="md"
        >
            <DrawerOverlay />
            <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader color="#344055">Delivery Status History</DrawerHeader>

            <DrawerBody>
                <Text pb={3} fontSize='24px' textTransform='uppercase' color="gray.800" fontWeight='600'>{historyx?.trackTitle}</Text>
                <Divider />
                <Flex mt={5} direction="row" alignItems="center">
                  <Heading color="gray.800" size="md" mr={3}>{historyx?.requestId}</Heading>
                  <Button size="xs" backgroundColor="#2a8960" color="#fff">Copy Request ID</Button>
                </Flex>
                <Text pb={4} mt={3} textAlign="left" lineHeight={1} size="xs" color="gray.500">Please copy and keep this code to revisit your request and track progress.</Text>
                <Divider />

                <Box pt={5}>
                  {historyx?.trackStages.map(({stageTitle}, index) => {
                    return (
                      <Box key={index}>
                        <Flex direction='row'>
                          <CheckCircleIcon marginTop="2px" mr={3} fontSize={20} color='green' />
                          <Text mb={10}>{stageTitle}</Text>
                        </Flex>
                      </Box>
                      
                      
                    )
                  })}
                </Box>
                
            </DrawerBody>
            </DrawerContent>
        </Drawer> */}
        </>
    // </div>
  );
};

export default Navbar;
