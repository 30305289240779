import React, { useState } from 'react';
import AddCustomer from '../request-components/add-customer/addCustomer';
import './customers.scss';
import CustomerList from '../../static/customers';
import Overview from '../dashboard-components/overview/overview';
import ViewCustomer from './view-customer/viewCustomer';
import { useQuery } from 'react-query';
import { getAllCustomers } from '../../services/customerServices';
import Loader from '../loader/loader';
import axios from 'axios';
import { baseUrl } from '../../config.json'
import { toast, ToastContainer } from 'react-toastify'

const config = {
  headers: {
     "Authorization": `Bearer ${localStorage.WEHAUL_MERCHANT_TOKEN}`
     }
}


const Customers = () => {
    const [customerView, setCustomerView] = useState(false);
    const [userId, setUserId] = useState('');

    const { data, error, isLoading } = useQuery('getAllCustomers', getAllCustomers);
    if(isLoading) {
      console.log('loading')
    } else if (data) {
      console.log('getAllCustomers', data.data.data)
    } else {
      console.log(error)
    }

    const getCustomerDetails = (id) => {
      setCustomerView(true)
      console.log('hi', id);
      setUserId(id)
    }

    const deleteCustomer = (id) => {
      // setUserId(id);
      axios.delete(`${baseUrl}/merchant/delete-customer/${id}`, config)
      .then(res => toast.success(res.data.data, {
        position: toast.POSITION.TOP_CENTER
      }))
      .then(res => window.location.reload())
      .catch(err => toast.error('An error occured, please try again', {
        position: toast.POSITION.TOP_CENTER
      }))

    }

    const banCustomer = (id) => {
        // setUserId(id)
        
        axios.put(`${baseUrl}/merchant/ban-customer/${id}`, config)
        .then(res => toast.success(res.data.data, {
          position: toast.POSITION.TOP_CENTER
        }))
        .catch(err => toast.error("An error occured, please try again", {
          position: toast.POSITION.TOP_CENTER
        }))
    }

    return ( 
        <div className="customers">
            <Overview />

            <div className="heading">
                <div className="left-items">
                    <div className="title"><i onClick={() => window.location.href = '/dashboard'} className="fas fa-home"></i><span>Customers</span></div>
                    <p className="request-count">{data?.data?.data.length}</p>
                    <p className="export-btn">Export</p>
                </div>
                <div className="right-items">
                    <AddCustomer />
                </div>
            </div>

            <table>
        <tr className="label-row">
          <th>Name</th>
          <th>Phone Number</th>
          <th>Email</th>
          <th>Drop-off Address</th>
          <th>Action</th>
        </tr>
        {isLoading ? <Loader />  : data?.data?.data.map(
          ({
            name,
            phoneNumber,
            email,
            address,
            _id: id
          }, index) => {
            return (
                <tr className="content-row" key={index}>
                  <td className="lefty pre"><p>{name}</p></td>
                  <td>{phoneNumber}</td>
                  <td>{email}</td>
                  <td>{address}</td>
                  <td>
                    <i onClick={() => getCustomerDetails(id)} style={{cursor: 'pointer', fontSize: '1.1em', color: '#113E82',margin: '0 5px', backgroundColor: '#e0f5f5', padding: '6px', borderRadius: '2px'}} className="fas fa-eye"></i>
                    <i onClick={() => deleteCustomer(id)} style={{cursor: 'pointer', fontSize: '1.1em', color: '#113E82',margin: '0 5px', backgroundColor: '#e0f5f5', padding: '6px', borderRadius: '2px'}} className="fas fa-trash"></i>
                    <i onClick={() => banCustomer(id)} style={{cursor: 'pointer', fontSize: '1.1em', color: '#F3402B',margin: '0 5px', backgroundColor: '#e0f5f5', padding: '6px', borderRadius: '2px'}} className="fas fa-ban"></i>
                  </td>
                </tr>
                )
          })}
        
      </table>
      {customerView && <ViewCustomer userId={userId} setCustomerView={setCustomerView} />}
        </div>
     );
}
 
export default Customers;