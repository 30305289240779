import React, { useState } from 'react';
import './tripLanding.scss';
import { Link } from 'react-router-dom';
import Overview from '../../dashboard-components/overview/overview';
import Triplist from '../../../static/trips';
import AllTrips from './all-trips/allTrips';
import PendingTrips from './pending-trips/pendingTrips';
import OngoingTrips from './ongoing-trips/ongoingTrips';
import CompletedTrips from './completed-trips/completedTrips';
import CancelledTrips from './cancelled-trips/cancelled-trips';
import { useQuery } from 'react-query';
import { getAllTrips } from '../../../services/tripServices'
import Loader from '../../loader/loader';

import ReactStars from "react-rating-stars-component";

const TripLanding = () => {
    const [page, setPage] = useState('all');

    const { data, error, isLoading } = useQuery('getAllTrips', getAllTrips)
    if(isLoading) {
        console.log('loading all trips')
    } else if (data) {
        console.log('get all trips', data.data.data)
    } else {
        console.log(error)
    }
    return ( 
        <div className="trip-landing">
            <Overview />
            <div className="heading">
                <div className="left-items">
                {/* <i onClick={() => window.location.href = '/dashboard'} className="fas fa-home"></i> */}
                    <div className="title"><span>Trips</span></div>
                    <p className="request-count">{data?.data?.data.length}</p>
                    {/* <div className="field" style={{marginTop: '-9px'}}>
                        <input type="text" style={{ padding: "10px 15px", outline: 'none', margin: 'auto', width: '300px'}}/>
                    </div> */}
                </div>
                <div className="right-items">
                  <Link style={{textDecoration:'none'}} onClick={() => window.location.href = '/dashboard/request/make-request'}><p className='add-action'><i className="fas fa-plus"></i>Request New Trip</p></Link>
                </div>
            </div>

            <div className="nav">
                <p onClick={() => setPage('all')} className={page === 'all' ? 'active-all nav-items' : 'nav-items'}>ALL TRIPS</p>
                <p onClick={() => setPage('pending')} className={page === 'pending' ? 'active-pending nav-items' : 'nav-items'}>PENDING TRIPS</p>
                <p onClick={() => setPage('ongoing')} className={page === 'ongoing' ? 'active-ongoing nav-items' : 'nav-items'}>ON-GOING TRIPS</p>
                <p onClick={() => setPage('completed')} className={page === 'completed' ? 'active-completed nav-items' : 'nav-items'}>COMPLETED TRIPS</p>
                <p onClick={() => setPage('cancelled')} className={page === 'cancelled' ? 'active-cancelled nav-items' : 'nav-items'}>CANCELLED TRIPS</p>
            </div>

            {isLoading ? <Loader /> : page === 'all' && <AllTrips data={data?.data?.data} />}
            {isLoading ? <Loader /> : page === 'pending' && <PendingTrips data={data?.data?.data} />}
            {isLoading ? <Loader /> : page === 'ongoing' && <OngoingTrips data={data?.data?.data} />}
            {isLoading ? <Loader /> : page === 'completed' && <CompletedTrips data={data?.data?.data} />}
            {isLoading ? <Loader /> : page === 'cancelled' && <CancelledTrips data={data?.data?.data} />}
        </div>
     );
}
 
export default TripLanding;