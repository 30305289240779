import React, { useEffect, useState } from "react";
import ButtonLoader from "../../components/buttonLoader/buttonLoader";
import Google from "./googleIcon.svg";
import axios from "axios";
import { baseUrl, herokuBaseUrl } from '../../config.json';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import PasswordChecklist from "react-password-checklist";
import './signup.scss';
import {Link} from 'react-router-dom';

const config = {
  headers: {
     "Authorization": `Bearer ${localStorage.WEHAUL_CUSTOMER_TOKEN}`
     }
}

const Signup = (props) => {
  const [role, setRole] = useState("");
  const [number, setNumber] = useState("");
  const [wNumber, setWNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [refCode, setRefCode] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fullname, setFullname] = useState('');
  const [country, setCountry] = useState('');

  const [countries, setCountries] = useState(["Nigeria", "Ghana", "United States"]);

  const history = useHistory();
  console.log("hello",props)

  useEffect(() => {
    axios.get(`${herokuBaseUrl}/admin/retrieve-countries`, config)
    .then(res => console.log(res?.data))
    .catch(err => console.log(err))
  }, [])

  const search = useLocation().search;
  const nameq = new URLSearchParams(search).get("name")
  const emailq = new URLSearchParams(search).get("email")
  const phoneq = new URLSearchParams(search).get("phone")

  const formData = {
        "name": fullname || nameq,
        "phoneNumber": number || phoneq,
        "whatsAppNumber" : wNumber,
        "email": email || emailq,
        "country": country,
        "referralCode": refCode,
        "password": password,
        "userClass": 'customer'
  }
  const formData2 = {
        "name": fullname || nameq,
        "phoneNumber": number || phoneq,
        "whatsAppNumber" : wNumber,
        "country": country,
        "email": email || emailq,
        "password": password,
        "userClass": 'customer'
  }

  const handleSubmit = async e => {
    e.preventDefault();
    
    const verify = {
      email,
      userClass: "customer"
    }
    if(password === confirmPassword) {
      if(refCode.length > 1) {
        setLoading(true)
        try {
            const { data } = await axios.post(`${herokuBaseUrl}/auth/sign-up`, formData)
            setLoading(false)
            toast.success(data?.message, {
              position: toast.POSITION.TOP_CENTER
            })
            history.push("/")
         } catch (error) {
          setLoading(false)
            toast.error(error?.response?.data?.message, {
                position: toast.POSITION.TOP_CENTER
            })
         }
      } else {
        setLoading(true)
        try {
            const { data } = await axios.post(`${herokuBaseUrl}/auth/sign-up`, formData2)
            setLoading(false)
            toast.success(data?.message, {
              position: toast.POSITION.TOP_CENTER
            })
            // try {
            //   const res = await axios.post(`${herokuBaseUrl}/account/email-verification`, verify)
            //   console.log('email verification function', res.data)
            // } catch(error) {
            //   console.log(error?.response?.data?.message)
            // }
            history.push("/")
         } catch (error) {
          setLoading(false)
            toast.error(error?.response?.data?.message, {
                position: toast.POSITION.TOP_CENTER
            })
            
         }
      }
      
    } else {
        toast.error('password mismatch', {
            position: toast.POSITION.TOP_CENTER
        })
        setLoading(false)
    }

    // else if(number.length < 11) {
    //     toast.error('invalid phone number', {
    //         position: toast.POSITION.TOP_CENTER
    //     })
    }

    function Button() {
      if(password.length >= 8 && confirmPassword.length && password === confirmPassword) {
        return <><button type="submit">{loading && <ButtonLoader />}Sign up</button><br /><br /></>
      } else {
        return  <><button disabled type="submit">{loading && <ButtonLoader />}Sign up</button><br /><br /></>
      }
    }
    

  return (
    <div className="sign-up">
      <div className="container">
        <div className="left"></div>
        <div className="right">
          <form onSubmit={handleSubmit}>
            <h3>Customer Sign up</h3>
            <input
              type="text"
              placeholder="Full Name"
              required
              value={fullname || nameq}
              onChange={(e) => setFullname(e.target.value || nameq)}
            />{" "}
            <br />
            <input
              type="email"
              placeholder="Email"
              required
              value={email || emailq}
              onChange={(e) => setEmail(e.target.value || emailq)}
            />{" "}
            <br />
            <select onChange={e => setCountry(e.target.value)}>
              <option selected disabled style={{color: "green", fontSize: "10px !important"}}>Country</option>
              {countries.map((i, index) => {
                return (
                  <option key={index} value={i.toLowerCase()}>{i}</option>
                )
              })}
            </select>
            <input
              type="tel"
              placeholder="Phone Number"
              required
              minLength={11}
              maxLength={11}
              value={number || phoneq}
              onChange={(e) => setNumber(e.target.value || phoneq)}
            />{" "}
            <input
              type="tel"
              placeholder="Whatsapp Number"
              minLength={11}
              maxLength={11}
              value={wNumber}
              onChange={(e) => setWNumber(e.target.value)}
            />{" "}
            <br />
            
            <div className="p-word">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {showPassword && (
                <i
                  onClick={() => setShowPassword((prev) => !prev)}
                  className="fas fa-eye"
                ></i>
              )}
              {!showPassword && (
                <i
                  onClick={() => setShowPassword((prev) => !prev)}
                  className="fas fa-eye-slash"
                ></i>
              )}
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Re-enter Password"
                required
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {showPassword && (
                <i
                  onClick={() => setShowPassword((prev) => !prev)}
                  className="fas fa-eye"
                ></i>
              )}
              {!showPassword && (
                <i
                  onClick={() => setShowPassword((prev) => !prev)}
                  className="fas fa-eye-slash"
                ></i>
              )}
              <input
                type="text"
                placeholder="Referral Code (Optional)"
                value={refCode}
                style={{textTransform: "uppercase"}}
                onChange={(e) => setRefCode(e.target.value.toUpperCase())}
              />
<br /><br />
                <p className="terms" style={{color: "gray"}}>By Signing up you agree to our <Link style={{color: "#113E82", textDecoration: "none"}} to="/terms">Terms of Use</Link> </p>
<br />
              {password.length > 0 && <PasswordChecklist
              rules={["minLength","number","match"]}
              minLength={8}
              value={password}
              className="validation"
              iconSize={11}
              valueAgain={confirmPassword}
              messages={{
                minLength: "8 characters minimum",
                // specialChar: "Contains a special character",
                number: "Contains a number",
                // capital: "Contains a capital number",
                match: "Passwords match",
              }}
            />}
            </div>
            <Button />
            

          

            {/* <p className="forgot">or</p>
            <div className="google">
              <img
                style={{ transform: "translateY(7px)", marginRight: "8px" }}
                src={Google}
                alt=""
              />
              <span>Sign in with google</span>
            </div> */}
          </form>
          <Link to="/" style={{textDecoration: "none"}}><p className="forgot">Have an account already? <span style={{color: "#113E82"}} className="forgot">Sign in</span></p></Link>
        </div>
      </div>
    </div>
  );
};

export default Signup;
