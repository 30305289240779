import React, {useState} from 'react';
import './managePassword.scss';
import axios from 'axios';
import {baseUrl} from '../../config.json'
import { toast } from 'react-toastify';
import ButtonLoader from '../../components/buttonLoader/buttonLoader';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

const ManagePassword = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    const history = useHistory();

    const config = {
        headers: {
           "Authorization": `Bearer ${localStorage.WEHAUL_CUSTOMER_TOKEN}`
           }
    }

    const datax = {
        oldPassword,
        "newPassword": password
    }

    const changePassword = async (e) => {
        e.preventDefault();
        setLoading(true)
        if(password === confirmPassword) {
        try {
            const {data} = await axios.put(`${baseUrl}/auth/customer/change-password`, datax, config)
            console.log(data)
            setLoading(false)
            toast.success(data.message)
            setOldPassword('')
            setPassword('')
            setConfirmPassword('')
        } catch (error) {
            toast.error(error?.response?.data?.message)
            setLoading(false)
        }
      } else {
          toast.error("new password mismatch, try again")
          setLoading(false)
      }
    }

    return (
        <form onSubmit={changePassword} className="manage-password">
            <div onClick={() => window.location.href = '/dashboard/settings'} className="nav-back" style={{marginBottom: "15px", cursor: "pointer"}}>
                <i style={{marginRight: "5px"}} className="fas fa-arrow-left"></i><span>Back</span>
            </div>
            <div className="p-word">
              <input required type="text" value={oldPassword} onChange={e => setOldPassword(e.target.value)} placeholder="Enter Old Password" />
              <input
                type={showPassword ? "text" : "password"}
                placeholder="New Password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {showPassword && (
                <i
                  onClick={() => setShowPassword((prev) => !prev)}
                  className="fas fa-eye"
                ></i>
              )}
              {!showPassword && (
                <i
                  onClick={() => setShowPassword((prev) => !prev)}
                  className="fas fa-eye-slash"
                ></i>
              )}
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Re-enter New Password"
                required
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {showPassword && (
                <i
                  onClick={() => setShowPassword((prev) => !prev)}
                  className="fas fa-eye"
                ></i>
              )}
              {!showPassword && (
                <i
                  onClick={() => setShowPassword((prev) => !prev)}
                  className="fas fa-eye-slash"
                ></i>
              )}
            </div>
            <button type="submit">{loading ? <ButtonLoader /> : "Save new password"}</button>
        </form>
    )
}

export default ManagePassword
