import axios from 'axios';
import { baseUrl } from '../config.json';

const config = {
    headers: {
       "Authorization": `Bearer ${localStorage.WEHAUL_CUSTOMER_TOKEN}`
       }
}

export async function getAllRoutes() {
    return axios.get(`${baseUrl}/admin/retrieve-routes`, config)
}

export async function getAllVehicles() {
    return axios.get(`${baseUrl}/admin/retrieve-vehicles`, config)
}
export async function getTrucks() {
    return axios.get(`${baseUrl}/admin/retrieve-vehicles?category=trucks`, config)
}