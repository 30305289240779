import React, {useState} from 'react';
import './verification.scss';
import Mail from './mail.png';
import { useSelector } from 'react-redux';
import ButtonLoader from '../../components/buttonLoader/buttonLoader';
import {herokuBaseUrl} from '../../config.json';
import axios from 'axios';
import { toast } from 'react-toastify';

const Verification = (props) => {
    const [loading, setLoading] = useState(false)
    const currentUser = useSelector(({ userData }) => userData.currentUser);

    const verificationId = props.match.params.verificationId;

    const verify = async () => {
        setLoading(true)
        const reqData = {
            userClass: "customer"
        }
        try {
            const {data} = await axios.put(`${herokuBaseUrl}/account/verify-email/${verificationId}`, reqData)
            toast.success(data.message)
            setLoading(false)
            window.location.href = '/'
        } catch (error) {
            toast.error(error?.response?.data?.message)
            setLoading(false)
        }
    }

    return (
        <div className="verifi">
            <img src={Mail} alt="email verification illustration" />
            <p className="title">Verify your email address</p>
            <p className="para">You have selected <strong>{currentUser?.data?.email}</strong> as the email address for your account. <br /> Please verify this email address by clicking the button below.</p>
            <button onClick={verify}>{loading ? <ButtonLoader /> : "Verify your email"}</button>
        </div>
    )
}

export default Verification
