import React, { useState } from "react";
import "./settings.scss";
import { useSelector } from "react-redux";
import RippleLoader from "../../components/rippleLoader/rippleLoader";
// import { updateUserProfile } from "../../services/settingsService";
import banksData from "../../utils/banks";
import ButtonLoader from "../../components/buttonLoader/buttonLoader";
import axios from "axios";
import { baseUrl } from "../../config.json";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Compressor from 'compressorjs'

const Settings = () => {

  const currentUser = useSelector(({ userData }) => userData.currentUser);
  console.log(currentUser.data);
  const {
    name,
    phoneNumber,
    email,
    address,
    pickUpAddress,
    accountInformation,
  } = currentUser.data;

  const [phoneN, setPhoneN] = useState(phoneNumber);
  const [addressN, setAddressN] = useState(address);

  const [accountName, setAccountName] = useState(accountInformation?.accountName);
  const [accountNumber, setAccountNumber] = useState(accountInformation?.accountNumber);
  const [accountType, setAccountType] = useState(accountInformation?.accountType);
  const [bankName, setBankName] = useState(accountInformation?.bankName);

  const [compressedFile, setCompressedFile] = useState(null);

  const [ppUploadBtn, setppUploadBtn] = useState(false);

  const [profilePic, setProfilePic] = useState("");

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);

  const personalData = {
    phoneNumber: phoneN,
    address: addressN
  }

  const handlePersonalSubmit = async (e) => {
    e.preventDefault();
    setLoading3(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.WEHAUL_CUSTOMER_TOKEN}`,
      },
    };
    try {
      const res = await axios.put(
        `${baseUrl}/customer/settings/edit-profile`,
        personalData,
        config
      );
      console.log(res.data);
      setLoading3(false);
      toast.success(res.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (error) {
      console.log(error.response.data.message);
      setLoading3(false);
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }

  const tempData = {
    accountInformation: {
      accountName,
      accountNumber,
      accountType,
      bankName,
    },
  };

  const handleAcctSumbit = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(tempData);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.WEHAUL_CUSTOMER_TOKEN}`,
      },
    };
    try {
      const res = await axios.put(
        `${baseUrl}/customer/settings/edit-profile`,
        tempData,
        config
      );
      console.log(res.data);
      setLoading(false);
      toast.success(res.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (error) {
      console.log(error.response.data.message);
      setLoading(false);
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  
 


  // const handlePPchange = (e) => {
  //   setppUploadBtn(true);
  //   setProfilePic(e.target.files[0]);
  // };

  const uploadImg = async (e) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.WEHAUL_CUSTOMER_TOKEN}`,
      },
    };
    const formData = new FormData();
    formData.append("profilePicture", compressedFile);
    setLoading(true);

    try {
      const res = await axios.put(
        `${baseUrl}/customer/settings/edit-profile`,
        formData,
        config
      );
      console.log(res.data);
      toast.success(res.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setppUploadBtn(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handleCompressedUpload = (e) => {
    const image = e.target.files[0];
    new Compressor(image, {
      quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        setppUploadBtn(true);
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.        
        setCompressedFile(compressedResult)
        console.log(compressedResult)
      },
    });
  };

  return (
    <div className="settings">
      {!currentUser?.data ? (
        <RippleLoader />
      ) : (
        <div className="cover">
          <div className="top-head">
            <i className="fas fa-home"></i>
            <p className="top-head-left">Settings</p>
          </div>

          <p className="pg-title">Account Settings</p>
          <div className="img-deet-wrap" style={{ backgroundColor: "fff" }}>
            <div className="img-deet">
              <div
                className="img"
                style={{
                  position: "relative",
                  backgroundImage: `url(${
                    currentUser ? currentUser.data.profilePicture : null
                  })`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <input
                  style={{
                    backgroundColor: "red",
                    width: "120px",
                    height: "120px",
                    borderRadius: "50%",
                    position: "absolute",
                    opacity: "0",
                    zIndex: "2",
                    cursor: "pointer",
                  }}
                  type="file"
                  required
                  onChange={e => handleCompressedUpload(e)}
                />
                <i
                  style={{
                    backgroundColor: "#35aa78",
                    borderRadius: "50%",
                    padding: "8px",
                    color: "#fff",
                    zIndex: "1",
                    fontSize: "1.3em",
                    position: "absolute",
                    top: "60%",
                    right: "0",
                    cursor: "pointer",
                  }}
                  className="far fa-edit"
                ></i>
              </div>

              <div className="deet">
                <p className="name">{name}</p>
                <p className="account-number">{phoneNumber}</p>
                <p className="mail">{email}</p>
              </div>
            </div>
            {ppUploadBtn && (
              <button onClick={uploadImg}>
                {loading && <ButtonLoader />}Update profile image
              </button>
            )}
          </div>

          <div className="form">
            <div className="personal-info">
              <div className="title">Personal Information</div>
              <form onSubmit={handlePersonalSubmit} className="inputs">
                <div className="input">
                  <label htmlFor="full-name">Full Name</label>
                  <br />
                  <i className="far fa-user"></i>
                  <input id="full-name" placeholder={name} value={name} type="text" />
                </div>
                <div className="input">
                  <label htmlFor="phone-number">Phone Number</label>
                  <br />
                  <i className="fas fa-phone-alt"></i>
                  <input
                    id="phone-number"
                    value={phoneN}
                    type="tel"
                    onChange={e => setPhoneN(e.target.value)}
                  />
                </div>
                <div className="input">
                  <label htmlFor="email">Email Address</label>
                  <br />
                  <i className="far fa-envelope"></i>
                  <input id="email" value={email} type="email" />
                </div>
                <div className="input">
                  <label htmlFor="address">Address</label>
                  <br />
                  <i className="fas fa-location-arrow"></i>
                  <input id="address" value={addressN} onChange={e => setAddressN(e.target.value)} type="text" />
                </div>
              </form>
              <br />
              <br />
              <button onClick={handlePersonalSubmit} type="submit">{loading3 && <ButtonLoader />} Save</button>
              <Link style={{textDecoration: "none"}} to="/dashboard/manage-password">
              <p className="password">Manage Password</p>
              </Link>
            </div>


            <div className="acct-details">
              <div className="title">Account Details</div>
              <form onSubmit={handleAcctSumbit}>
                <div className="inputs">
                  <div className="input">
                    <label htmlFor="account-name">
                      Account Name
                    </label>
                    <br />
                    <i className="far fa-user"></i>
                    <input
                      id="account-name"
                      type="text"
                      value={accountName}
                      onChange={(e) => setAccountName(e.target.value)}
                    />
                  </div>
                  <div className="input">
                    <label htmlFor="account-number">
                      Account Number
                    </label>
                    <br />
                    <i className="fas fa-calculator"></i>
                    <input
                      id="account-number"
                      type="tel"
                      value={accountNumber}
                      onChange={(e) => setAccountNumber(e.target.value)}
                    />
                  </div>
                  <div className="input">
                    <label htmlFor="bank-name">
                      Bank Name
                    </label>
                    <br />
                    <i className="fas fa-university"></i>
                    <select
                      style={{
                        marginTop: "10px",
                        background: "#F9FBFF",
                        border: "1px solid #40D192",
                        borderRadius: "6px",
                        padding: "14px 30px",
                        boxSizing: "border-box",
                        width: "100%",
                        outline: "none",
                      }}
                      id="bank-name"
                      type="text"
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                    >
                      <option>--select bank--</option>
                      {banksData.map(({ name }, index) => {
                        return (
                          <option key={index} value={name}>
                            {name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="input">
                    <label htmlFor="account-type">
                      Account Type
                    </label>
                    <br />
                    <i className="fas fa-money-check-alt"></i>
                    <select
                      style={{
                        marginTop: "10px",
                        background: "#F9FBFF",
                        border: "1px solid #40D192",
                        borderRadius: "6px",
                        padding: "14px 30px",
                        boxSizing: "border-box",
                        width: "100%",
                        outline: "none",
                      }}
                      name="account-type"
                      id="account-type"
                      value={accountType}
                      onChange={(e) => setAccountType(e.target.value)}
                    >
                      <option>--select account type--</option>
                      <option value="savings">Savings</option>
                      <option value="current">Current</option>
                    </select>
                  </div>
                </div>
                <br />
                <button type="submit">
                  {loading && <ButtonLoader />} Save
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;


