import React from 'react';
import './withdrawal.scss';
import Withdrawallist from '../../../static/withdrawals';
import RippleLoader from '../../rippleLoader/rippleLoader';
import EmptyState from '../../emptyState/emptyState';

const Withdrawals = ({data}) => {
    return ( 
        <div className="withdrawals">
            {data?.length === 0 ? <EmptyState /> : 
            <div className="cover">
            <p className='top-title'>Recent Withdrawal</p>
            <table>
                <tr className='label-row'>
                    <th>S/N</th>
                    <th>Amount</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Reference</th>
                </tr>
                    
                    {data?.map(({ amount, date, reference, status, _id: id }, index) => {
                        return (
                            <tr className='content-row' key={id}>
                               <td>{index + 1}</td>
                               <td>{amount}</td>
                               <td>{date}</td>
                               {status === 'unpaid' && <td><p className="unpaid">{status}</p></td>}
                               {status === 'paid' && <td><p className="paid">{status}</p></td>}
                               <td>{reference}</td>
                            </tr>
                        )
                    })}
            </table>
            </div>
            }
            
            
        </div>
     );
}
 
export default Withdrawals;