import React, { useState } from 'react';
import './wallet.scss';
import TransactionIcon from './transaction.svg';
import Visa from './visa.svg';
import MasterCard from './mastercard.svg';
import Logo from '../../assets/logo.svg';
import Deposits from './deposits/deposits';
import Withdrawals from './withdrawal/withdrawal';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { getPayOutRequests, getWalletFunding } from '../../services/walletServices'
import EmptyState from '../emptyState/emptyState';
import axios from 'axios';
import { baseUrl } from '../../config.json';
import AmountModal from "./amountModal/amountModal";
import WithdrawModal from './withdrawModal/withdrawModal';


const Wallet = () => {
    const [page, setPage] = useState('deposits');
    const [depositAmount, setDepositAmount]= useState(null);
    const [showAmountModal, setShowAmountModal] = useState(false);
    const [showWithdrawModal, setShowWithdrawModal] = useState(false);

    const currentUser = useSelector(({userData}) => userData.currentUser);
    console.log(currentUser.data);

    const { data, error, isLoading } = useQuery('getPayoutRequests', getPayOutRequests);
    if(isLoading) {
        console.log('loading payout requests')
    } else if (data) {
        console.log('getPayOutRequests', data.data.data);
    } else {
        console.log(error)
    }

    const { data: datax, error: errorx, isLoading: isLoadingx } = useQuery('getWalletFunding', getWalletFunding);
    if(isLoadingx) {
        console.log('loading wallet funding');
    } else if (datax) {
        console.log('getWalletFunding', datax.data.data);
    } else {
        console.log(errorx)
    }

    const fundWallet = e => {
        e.preventDefault();
        console.log(depositAmount)
    }

    const { walletBalance } = currentUser?.data;
    return ( 
        <div className="wallet">
          {!currentUser?.data ? <EmptyState /> : 
             <div className="cover">
            <div className="top-head">
              <i className="fas fa-home"></i><p className="top-head-left">Wallet</p>
              {/* <p onClick={() => setShowWithdrawModal(true)} id='btnx'><i className="fas fa-wallet"></i>Withdraw Fund</p> */}
              {showWithdrawModal && <WithdrawModal setShowWithdrawModal={setShowWithdrawModal} />}
            </div>
 
            {/* <div className="flex-conty">
                 <div className="box current-balance">
                    <i className="fas fa-wallet"></i>
                    <div className="side-txt">
                        <p className="label">Current Balance</p>
                        <p className="val">{walletBalance.toLocaleString()}</p>
                    </div>
                 </div>
                 <div className=" box transactions">
                      <img src={TransactionIcon} alt="transacticon" />
                      <div className="side-txt">
                        <p className="label">Transactions</p>
                        <p className="val">nil</p>
                    </div>
                 </div>
                 <div className=" box instant-deposit">
                      <div className="top">Instant Deposit</div>
                      <div className="bottom"> 
                          <div className="icons">
                              <div className="img" style={{backgroundImage: `url(${MasterCard})`}}></div>
                              <div className="img" style={{backgroundImage: `url(${Visa})`}}></div>
                          </div>
                          <form className="form" onSubmit={fundWallet}>
                              <button onClick={() => setShowAmountModal(true)} type='submit'>Deposit</button>
                          </form>
                      </div>
                      {showAmountModal && <AmountModal setShowAmountModal={setShowAmountModal} />}
                 </div>
                 <div className="box deposit-account">
                      <div className="top">
                          <img src={Logo} alt="wehaulogo" />
                          <p>Deposit Account</p>
                      </div>
                      <div className="bottom">
                          <p>Bank Name:</p>
                          <p>Account Name:</p>
                          <p>Account Number:</p>
                          <ul>
                              <li>Make deposit to the this account from any branch near you.</li>
                              <li>Send an email and attach a scanned copy of your receipt or the receipt/transaction number along your user id to...</li>
                              <li>Or Send the receipt/transaction number along with your user id to...</li>
                          </ul>
                      </div>
                 </div>
             </div> */}
 
             <div className="dep-wit">
                 <div className="nav">
                     <p className={page === 'deposits' ? 'active-dep nav-items' : 'nav-items'} onClick={() => setPage('deposits')}>Transactions</p>
                     {/* <p className={page === 'withdraw' ? 'active-wit nav-items' : 'nav-items'} onClick={() => setPage('withdraw')}>Withdrawals</p> */}
                 </div>
             </div>
 
             {page === 'deposits' && <Deposits data={datax?.data?.data} />}
             {page === 'withdraw' && <Withdrawals data={data?.data?.data} />}
             </div>
          }
        </div>
     );
}
 
export default Wallet;