import React, { useRef, useState } from "react";
import "./allTrips.scss";
import { Link } from "react-router-dom";
import TripView from "../../trip-view/tripView";
import EmptyState from "../../../emptyState/emptyState";
import { useHistory } from "react-router";
import ReactStars from "react-rating-stars-component";
import axios from "axios";
import { useSelector } from "react-redux";
import {baseUrl} from '../../../../config.json';
import { toast } from "react-toastify";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Spinner,
  Flex,
  Box,
  Text,
  Divider,
  Badge,
  AvatarBadge,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from '@chakra-ui/react';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import Logo from './wehaulogo.png';
import Dots from './dots.svg';

const config = {
  headers: {
     "Authorization": `Bearer ${localStorage.WEHAUL_CUSTOMER_TOKEN}`
     }
}

const AllTrips = ({ data }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const currentUser = useSelector(({ userData }) => userData.currentUser);
  const {
    name
  } = currentUser.data;

  const [tripViewShown, setTripViewShown] = useState(false);
  const [tripId, setTripId] = useState("");
  const [driverId, setDriverId] = useState("");
  const [loader, setLoader] = useState(false);

  const [clicked, setClicked] = useState(false);

  const [receiptLoader, setReceptLoader] = useState(true);
  const [receiptData, setReceptData] = useState(true);

  const toggle = (index, driverId, tripId) => {
    if(clicked === index) {
      return setClicked(null)
    }
    setClicked(index)
    setDriverId(driverId)
    setTripId(tripId)
  }

  const history = useHistory();

  const getTripId = async(id) => {
    // console.log(id);
    setTripId(id);
    setTripViewShown(true)
  };

  const getTripIdx = async(id) => {
    await setTripId(id);
    await onOpen()
    // console.log("workcc",id);
    getReceiptDetails(id)
  };

  const handleChatRedirect = async (id, name, picture) => {
    console.log(id)
    await history.push(`dashboard/chat/${id}`, {chatRecipient: id, name, picture})
    window.location.reload()
}

const ratingChanged = async (newRating) => {
  console.log(newRating);
  const formData = {
    rating: newRating
  }
  setLoader(true)
  try {
    const {data} = await axios.post(`${baseUrl}/customer/trip/${tripId}/rate-driver/${driverId}`, formData, config)
    console.log(data)
    toast.success(data?.message)
    window.location.reload()
  } catch (error) {
    console.log(error?.response?.data?.message)
  }
};

const getReceiptDetails = async(id) => {
    try {
      const {data} = await axios.get(`${baseUrl}/customer/trip/${id}/trip-details`, config)
      // console.log(data?.data)
      setReceptData(data?.data)
      setReceptLoader(false)
    } catch(err) {
      console.log(err?.response?.data?.message)
    }
}

const inputRef = useRef(null);
  const printDocument = () => {
    html2canvas(inputRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 0, 0);
      pdf.save("wehaul-trip-receipt.pdf");
    });
  };

  return (
    <div className="all-trips">
      {data?.length === 0 ? <EmptyState /> : 
      <table>
        <tr className="label-row">
          <th>Pick-up</th>
          {/* <th>Drop-off</th> */}
          <th>Driver</th>
          <th>Status</th>
          <th>Vehicle Type</th>
          <th>Trip Fee</th>
          {/* <th>Customer Name</th> */}
          <th>Pickup Date</th>
          <th>Action</th>
        </tr>
        {data.map(
          (
            { request, acceptedBy, tripFee, FeeWithCommission, tripStatus, status, isRated, _id: id },
            index
          ) => {
            return (
              <tr className="content-row" key={index}>
                <td className="lefty pre">{request?.pickUpAddress}</td>
                {/* <td className="lefty">{request.destinationAddress}</td> */}
                <td>{acceptedBy.name}</td>
                {tripStatus === "pending" && (
                  <td>
                    <p className="pending-status">{tripStatus}</p>
                  </td>
                )}
                {tripStatus === "on-going" && (
                  <td>
                    <p className="ongoing-status">{tripStatus}</p>
                  </td>
                )}
                {tripStatus === "completed" && (
                  <td>
                    <p className="completed-status">{tripStatus}</p>
                  </td>
                )}
                {status === "cancelled" && (
                  <td>
                    <p className="cancelled-status">{tripStatus}</p>
                  </td>
                )}
                <td>{request?.vehicleType}</td>
                <td>{FeeWithCommission}</td>
                {/* <td>{request.receiverDetails.name}</td> */}
                <td>{request?.pickUpDate}</td>
                <td>
                <Menu size={"xs"}>
                  <MenuButton>
                    <Image cursor={"pointer"} ml="1.5rem" src={Dots} />
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={() => getTripId(id)}>View</MenuItem>
                    <MenuDivider />
                    <a target="_blank" href={"https://wa.me/" + "234" + acceptedBy?.phoneNumber.substring(1) + "?text=Hi, I'm " + name + ". I have a pending delivery of " + request?.requestTitle + ", to be picked up by you from " + request?.pickUpAddress + " and delivered to " + request?.destinationAddress + " on WeHaul247"} data-action="share/whatsapp/share">
                      <MenuItem onClick={() => getTripId(id)}>Chat with Driver
                        <i
                          // onClick={() => handleChatRedirect(acceptedBy.id, acceptedBy.name, acceptedBy.profilePicture)}
                          style={{
                            color: "#fff",
                            cursor: "pointer",
                            backgroundColor: "#40D192",
                            padding: "4px",
                            borderRadius: "2px",
                            marginLeft: "5px"
                          }}
                          className="far fa-comment-dots"
                        ></i>
                    </MenuItem> </a>
                    {tripStatus === "completed" && 
                    <>
                    <MenuDivider />
                    <MenuItem onClick={() => getTripIdx(id)}>Generate Receipt
                        <i
                          style={{
                            color: "#fff",
                            backgroundColor: "#11CDC8",
                            marginBottom: "4px",
                            marginTop: "4px",
                            cursor: "pointer",
                            padding: "3px 6px",
                            borderRadius: "2px",
                            marginLeft: "5px"
                          }}
                          className="fas fa-file"
                        ></i>
                      </MenuItem>
                      </>}
                    {tripStatus === "completed" && 
                    <>
                    <MenuDivider />
                    {/* {clicked !== index ? <button onClick={() => toggle(index, acceptedBy.id, id)} id={isRated ? "rated" : "unrated"} className="rate">Rate</button> :
                       <ReactStars
                        count={5}
                        onChange={ratingChanged}
                        size={24}
                        isHalf={true}
                        emptyIcon={<i className="far fa-star"></i>}
                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                        fullIcon={<i className="fa fa-star"></i>}
                        activeColor="#ffd700"
                        classNames="stars"
                      />} */}
                    {clicked !== index ? <MenuItem onClick={() => toggle(index, acceptedBy.id, id)} id={isRated ? "rated" : "unrated"}>Rate Driver</MenuItem>
                    :
                    <ReactStars
                        count={5}
                        onChange={ratingChanged}
                        size={24}
                        isHalf={true}
                        emptyIcon={<i className="far fa-star"></i>}
                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                        fullIcon={<i className="fa fa-star"></i>}
                        activeColor="#ffd700"
                        classNames="stars"
                      />}
                      </>}
                  
                    {/* <MenuDivider />
                    <MenuItem onClick={() => handleShare(id, request?._id)}>Share Link <i style={{marginLeft: ".5rem"}} className="fas fa-share"></i></MenuItem> */}
                  </MenuList>
                </Menu>
                  
                  
                </td>
              </tr>
            );
          }
        )}
      </table>}
      {tripViewShown && (
        <TripView tripId={tripId} setTripViewShown={setTripViewShown} />
      )}


    <Modal size={"md"} isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Download Receipt</ModalHeader>
          <ModalCloseButton _focus={{boxShadow: "none"}} />

          <ModalBody>
            {receiptLoader ? 
              <Flex justifyContent={"center"} mt={6}><Spinner /></Flex>
              :
              <Box id="divToPrint" ref={inputRef} padding={"1rem"} border={"1px dotted gray"}>
                  <Text fontWeight={"600"} fontSize="1.5rem" mb={4}>Trip Receipt</Text>
                  <Divider />
                  <Flex mt={3} justifyContent={"flex-end"}>
                    <Text>Date Created: <strong>{receiptData?.request?.createdAt.slice(0,10)}</strong></Text>
                  </Flex>
                  <Flex mb={3} justifyContent={"flex-end"}>
                    <Text>Date Completed: <strong>{receiptData?.request?.pickUpDate}</strong></Text>
                  </Flex>
                  <Divider />
                  <Flex justifyContent={"center"}>
                    <Badge colorScheme={"blue"} borderBottomRadius="6px" margin={"0 auto"} padding={".5rem .7rem"}>{receiptData?.request?.requestTitle}</Badge>
                  </Flex>
                  
                  
                  <Flex mt={5} justifyContent={"space-between"}>
                    <Text color="gray">🟩 Pick Up</Text>
                    <Text>{receiptData?.request?.pickUpAddress}</Text>
                  </Flex>
                  <Flex mt={3} mb={3} justifyContent={"space-between"}>
                    <Text color="gray">🟥 Destination</Text>
                    <Text>{receiptData?.request?.destinationAddress}</Text>
                  </Flex>
                  <Divider />
                  <Flex mt={3} justifyContent={"space-between"}>
                    <Text color="gray">Driver Name</Text>
                    <Text>{receiptData?.acceptedBy?.name}</Text>
                  </Flex>
                  <Flex mt={3} justifyContent={"space-between"}>
                    <Text color="gray">Driver Vehicle</Text>
                    <Text>{receiptData?.request?.vehicleType}</Text>
                  </Flex>
                  <Divider mt={8} />
                  <Flex justifyContent={"space-between"} alignItems="center">
                    <Text textAlign={"right"} fontSize={".9rem"} color="gray">Total</Text>
                    <Text mt={3} fontSize={"1.4rem"} fontWeight="600">₦{receiptData?.FeeWithCommission}</Text>
                  </Flex>
                  <Flex mt={8} justifyContent={"center"}>
                    <Image width={"20%"} src={Logo} />
                  </Flex>
              </Box>
            }
          </ModalBody>

          <ModalFooter mt={8}>
            <Button variant='ghost' onClick={onClose}>Close</Button>
            <Button colorScheme='blue' mr={3} onClick={printDocument}>
              Download
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default AllTrips;
