import React, { useState } from 'react';
import ButtonLoader from '../../../components/buttonLoader/buttonLoader';
import './createTicket.scss';
import axios from 'axios';
import { baseUrl } from '../../../config.json';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import LineLoader from '../../../components/lineLoader/lineLoader';
import { useSelector } from 'react-redux';

const CreateTicket = ({setTicketModal}) => {
    const [loading, setLoading] = useState(false);
    const [subject, setSubject] = useState("");
    const [desc, setDesc] = useState("");
    const [category, setCategory] = useState("");
    const [img, setImg] = useState('')

    const currentUser = useSelector(({ userData }) => userData.currentUser);
    // console.log(currentUser.data);
    const {name, email } = currentUser.data;


    const config = {
        headers: {
           "Authorization": `Bearer ${localStorage.WEHAUL_CUSTOMER_TOKEN}`
           }
     }

    //  const formdata = new FormData();
    //  formdata.append('name', name)
    //  formdata.append('email', email)
    //  formdata.append('subject', subject)
    //  formdata.append('description', desc)
    //  formdata.append('category', category)

    // const formdata = {
    //     name,
    //     email,
    //     subject,
    //     description: desc,
    //     category
    // }
//      const addTickets = async (e) => {
//          e.preventDefault();
//          setLoading(true)
//         const requestOptions = {
//           method: 'POST',
//           body: formdata,
//           headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.WEHAUL_CUSTOMER_TOKEN}` }
//       };
//       try {
//           const {data} = await fetch(`${baseUrl}/customer/ticket/create-ticket`, requestOptions)
//           console.log(data)
//           .then(res => res.json())
//           .then(res => toast.success(res.message))
//           .then(res => setLoading(false))
//       } catch (error) {
//           toast.error(error?.response?.formdata?.message)
//           setLoading(false)
//       }
//   }

  const formdata = new FormData();
      formdata.append("name", name)
      formdata.append("email", email)
      formdata.append("subject", subject)
      formdata.append("description", desc)
      formdata.append("category", category)
      formdata.append("attachment", img)

  const addTicket = async (e) => {
      e.preventDefault();
      setLoading(true)
      

      try {
          const {data} = await axios.post(`${baseUrl}/customer/ticket/create-ticket`, formdata, config)
          console.log(data.data)
          toast.success(data.message)
          setLoading(false)
          window.location.reload();
      } catch (error) {
        toast.error(error?.response?.data?.message)
        setLoading(false)
      }
  }

    

    return ( 
        <div className="create-ticket">
            <div className="wrapperv">
                <div className="modalv">
                    <i onClick={() => setTicketModal(false)} className="fas fa-times"></i>
                    <div className="top-contentv">
                        <p className="label">What do you need help with?</p>
                    </div>
                    <form onSubmit={addTicket}>
                        <div className="input">
                            <label htmlFor="subject">Subject</label><br />
                            <input onChange={e => setSubject(e.target.value)} name="subject" type="text" />
                        </div>
                        <div className="input">
                            <label htmlFor="desc">Description</label><br />
                            <p className="info">Please enter the details of your request. A member of our support staff will respond as soon as possible.</p>
                            <textarea onChange={e => setDesc(e.target.value)} name="desc" id="" cols="30" rows="6"></textarea>
                        </div>
                        <div className="input">
                            <label htmlFor="cat">Issue Category</label><br />
                            <select onChange={e => setCategory(e.target.value)} name="cat">
                                <option selected disabled>select issue category</option>
                                <option value="payment issues">Payment Issues</option>
                                <option value="driver issues">Driver Issues</option>
                                <option value="billing issues">Billing Issues</option>
                                <option value="technical issues">Technical Issues</option>
                                <option value="goods delivery">Goods Delivery</option>
                                <option value="inquiries">Inquiries</option>
                                <option value="others">Others</option>
                            </select>
                        </div>
                        <div className="input">
                            <label htmlFor="">Add an image</label>
                            <input type="file" onChange={e => setImg(e.target.files[0])} />
                        </div>
                        <div className="btn">
                            <button type="submit">{loading && <ButtonLoader />}Send Ticket</button>
                        </div>
                        
                    </form>
                </div>
            </div>
        </div>
     );
}
 
export default CreateTicket;