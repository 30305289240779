import React, { useEffect } from 'react';
import {  Redirect, Route, Switch } from 'react-router-dom';
import './App.css';
import Dashboard from './pages/dashboard/dashboard';
import Login from './pages/login/login';
import Signup from './pages/signup/signup';
import {useSelector} from 'react-redux'
import store from './redux/store';
import {loaduser, logout} from './redux/actions/auth';
import {useDispatch} from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import ProtectedRoute from './routing/protectedRoute/protectedRoute'
import AppLoader from './components/appLoader/appLoader';
import * as routes from './routing/route.js'
import RippleLoader from './components/rippleLoader/rippleLoader';
import jwt_decode from "jwt-decode";
import { useHistory } from 'react-router';
import {auth} from './firebase/firebase.utils';
import Verification from './pages/verification/verification';
import PasswordReset from './pages/passwordReset/passwordReset';
import ResetForm from './pages/passwordReset/reset-form/resetForm';
import WhatsappPay from './pages/whatsappPay/whatsappPay';
import Terms from './pages/terms/terms';
import PaySuccess from './pages/appPaymentSuccess/paySuccess';
import GuestRequest from './pages/guestRequest/guestRequest';
import GuestRequestx from './pages/guestRequest copy/guestRequest';

const state = store.getState();


const authData = state.userData.WEHAUL_CUSTOMER_TOKEN;
const currentUser = state.userData
console.log(authData, currentUser);
const authToken = localStorage.WEHAUL_CUSTOMER_TOKEN

function App() {
  const currentUser = useSelector(({userData})=>  userData.currentUser);
  const isAuthenticated = useSelector(({userData})=>  userData.isAuthenticated);
  console.log(currentUser);
  const dispatch = useDispatch();
  const history = useHistory();

  

 auth.onAuthStateChanged(async userAuth => {
    console.log(userAuth)
  })

  useEffect(() => {
    //  load user if user is logged in
    if(authToken){
      console.log('from ye app componnt')
      dispatch(loaduser(history))
    } 

    return ()=> null;
  }, [])

 if(!currentUser && localStorage.WEHAUL_CUSTOMER_TOKEN) {
   return <AppLoader />
 }

 if(localStorage.WEHAUL_CUSTOMER_TOKEN) {
   // redirect lo login when token expires
   let token = localStorage.WEHAUL_CUSTOMER_TOKEN
   const decoded = jwt_decode(token)
   console.log(decoded)
   const { exp } = jwt_decode(token)
   if (exp < Date.now() / 1000) {
     dispatch(logout())
  }
 }


  return (
  
      <div className="App">
        <ToastContainer position="top-center" />
        <Switch>
        <Route exact path={routes.Login} component={Login} />
          <Route exact path='/signup' component={Signup} />
          <Route exact path='/whatsapp-payment' component={WhatsappPay} />
          <Route exact path='/terms' component={Terms} />
          <Route exact path='/payment-success' component={PaySuccess} />
          <Route exact path='/reset-password-mail' component={PasswordReset} />
          <Route exact path='/reset-password/:resetToken' component={ResetForm} />
          <Route exact path='/verify-email/:verificationId' component={Verification} />
          <Route exact path='/viewTripDetails' component={GuestRequest} />
          <Route exact path='/viewTripDetails/:tid/:rid' component={GuestRequestx} />
          <ProtectedRoute path={routes.DashBoard} component={Dashboard} />
        </Switch>
      </div>

    
  );
}

export default App;
