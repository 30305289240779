import React, { useState } from "react";
import { Link } from "react-router-dom";
import EmptyState from "../../../emptyState/emptyState";
import TripView from "../../trip-view/tripView";
import ReactStars from "react-rating-stars-component";
import axios from "axios";
import {baseUrl} from '../../../../config.json';
import { toast } from "react-toastify";

const config = {
  headers: {
     "Authorization": `Bearer ${localStorage.WEHAUL_CUSTOMER_TOKEN}`
     }
}

const CompletedTrips = ({ data }) => {
  const [tripViewShown, setTripViewShown] = useState(false);
  const [tripId, setTripId] = useState("");
  const [driverId, setDriverId] = useState("");
  const [loader, setLoader] = useState(false);

  const [clicked, setClicked] = useState(false);

  const toggle = (index, driverId, tripId) => {
    if(clicked === index) {
      return setClicked(null)
    }
    setClicked(index)
    setDriverId(driverId)
    setTripId(tripId)
  }


  const getTripId = (id) => {
    console.log(id);
    setTripId(id);
    setTripViewShown(true);
  };


  

  const ratingChanged = async (newRating) => {
    console.log(newRating);
    const formData = {
      rating: newRating
    }
    setLoader(true)
    try {
      const {data} = await axios.post(`${baseUrl}/customer/trip/${tripId}/rate-driver/${driverId}`, formData, config)
      console.log(data)
      toast.success(data?.message)
      window.location.reload()
    } catch (error) {
      console.log(error?.response?.data?.message)
    }
  };

  return (
    <div className="all-trips">
      {data?.length === 0 ? (
        <EmptyState />
      ) : (
        <table>
          <tr className="label-row">
            <th>Pick-up</th>
            {/* <th>Drop-off</th> */}
            <th>Driver</th>
            <th>Status</th>
            <th>Vehicle Type</th>
            <th>Trip Fee</th>
            {/* <th>Customer Name</th> */}
            <th>Pickup Date</th>
            <th>Action</th>
          </tr>
          {data
            .filter((datax) => datax.tripStatus === "completed")
            .map(
              ({ request, acceptedBy, tripFee, tripStatus, status, _id: id }, index) => {
                return (
                  <tr className="content-row" key={index}>
                    <td className="lefty pre">{request?.pickUpAddress}</td>
                    {/* <td className="lefty">{request.destinationAddress}</td> */}
                    <td>{acceptedBy?.name}</td>
                    {tripStatus === "pending" && (
                      <td>
                        <p className="pending-status">{tripStatus}</p>
                      </td>
                    )}
                    {tripStatus === "on-going" && (
                      <td>
                        <p className="ongoing-status">{tripStatus}</p>
                      </td>
                    )}
                    {tripStatus === "completed" && (
                      <td>
                        <p className="completed-status">{tripStatus}</p>
                      </td>
                    )}
                    {status === "cancelled" && (
                      <td>
                        <p className="cancelled-status">{tripStatus}</p>
                      </td>
                    )}
                    <td>{request?.vehicleType}</td>
                    <td>{tripFee}</td>
                    {/* <td>{request.receiverDetails.name}</td> */}
                    <td>{request?.pickUpDate}</td>
                    {/* <td></td> */}
                    <td>
                      {tripStatus === "pending" && (
                        <div>
                          <i
                            onClick={() => getTripId(id)}
                            style={{
                              color: "#fff",
                              cursor: "pointer",
                              backgroundColor: "#FFBB00",
                              marginBottom: "4px",
                              padding: "3px",
                              borderRadius: "2px",
                            }}
                            className="fas fa-eye"
                          ></i>
                          <i
                            style={{
                              color: "#fff",
                              cursor: "pointer",
                              backgroundColor: "#40D192",
                              padding: "4px",
                              borderRadius: "2px",
                            }}
                            className="far fa-comment-dots"
                          ></i>
                        </div>
                      )}
                      {tripStatus === "completed" && (
                        <div>
                          <i
                            onClick={() => getTripId(id)}
                            style={{
                              color: "#fff",
                              cursor: "pointer",
                              backgroundColor: "#FFBB00",
                              marginBottom: "4px",
                              padding: "3px",
                              borderRadius: "2px",
                            }}
                            className="fas fa-eye"
                          ></i>
                          <br />
                          <i
                            style={{
                              color: "#fff",
                              cursor: "pointer",
                              backgroundColor: "#40D192",
                              padding: "4px",
                              borderRadius: "2px",
                            }}
                            className="far fa-comment-dots"
                          ></i>
                        </div>
                      )}
                      {tripStatus === "ongoing" && (
                        <div>
                          <i
                            onClick={() => getTripId(id)}
                            style={{
                              color: "#fff",
                              cursor: "pointer",
                              backgroundColor: "#FFBB00",
                              marginBottom: "4px",
                              padding: "3px",
                              borderRadius: "2px",
                            }}
                            className="fas fa-eye"
                          ></i>
                          <i
                            style={{
                              color: "#fff",
                              cursor: "pointer",
                              backgroundColor: "#40D192",
                              padding: "4px",
                              borderRadius: "2px",
                            }}
                            className="far fa-comment-dots"
                          ></i>
                        </div>
                      )}
                      {tripStatus === "cancelled" && (
                        <div>
                          <i
                            onClick={() => getTripId(id)}
                            style={{
                              color: "#fff",
                              cursor: "pointer",
                              backgroundColor: "#113E82",
                              padding: "3px",
                              borderRadius: "2px",
                            }}
                            className="fas fa-eye"
                          ></i>
                        </div>
                      )}
                      {clicked !== index ? <button onClick={() => toggle(index, acceptedBy?.id, id)} className="rate">Rate</button> :
                       <ReactStars
                        count={5}
                        onChange={ratingChanged}
                        size={24}
                        isHalf={true}
                        emptyIcon={<i className="far fa-star"></i>}
                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                        fullIcon={<i className="fa fa-star"></i>}
                        activeColor="#ffd700"
                        classNames="stars"
                      />}
                    </td>
                  </tr>
                );
              }
            )}
        </table>
      )}
      {tripViewShown && <TripView tripId={tripId} setTripViewShown={setTripViewShown} />}
      
    </div>
  );
};

export default CompletedTrips;
