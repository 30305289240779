

const Terms = () => {
    return (
        <div className="terms" style={{padding: "2rem"}}>
            <div className="top" style={{textAlign: "center"}}>
                <h2 className="title">WeHaul Customer/Merchant Terms of Service</h2>
            </div>
            <br />
            {/* <div style={{borderBottom: "2px solid #113E82"}} className="line"></div>
            <br /> */}
            <p>

            
<strong>
THIS FOLLOWING USER AGREEMENT DESCRIBES THE TERMS AND CONDITIONS ON WHICH WEHAUL LOGISTICS LTD. OFFERS YOU ACCESS TO THE WEHAUL PLATFORM.
These terms of service constitute a legally binding agreement (the “Agreement”) between you (“you,” or “your”) and WeHaul Ltd. (“WeHaul” “we,” “us” or “our”), a registered Nigerian Company, governing your use of the WeHaul application, website, Application and technology platform (collectively, the “WeHaul Platform”). <br /><br />
The WeHaul Platform provides a marketplace where persons (“customers”) who seek transportation of goods from one destination (“collection location”) to other destinations (“delivery location”) can be matched with drivers and vehicles to those destinations (“Partners/Drivers”). Drivers and Customers are collectively referred to herein as “Users” and each User shall create a User account that enables such User to access the WeHaul Platform. For purposes of this Agreement, the driving services provided by Drivers to Customers that are matched through the Platform shall be referred to collectively as the “Services”.<br /><br />
WeHaul does not provide transportation services, and WeHaul is not a transportation carrier. It is up to the driver to decide whether or not to offer a ride to a customer contacted through the WeHaul platform, and it is up to the customer to decide whether or not to accept a trip from any driver contacted through the WeHaul platform. Any decision by a user to offer or accept services once such user is matched through the WeHaul platform is a decision made in such user’s sole discretion. <br /><br />
By creating your User account and using the WeHaul Platform, you expressly acknowledge that you understand this Agreement and accept all of its terms. If you do not agree to be bound by the terms and conditions of this agreement, you may not use or access the WeHaul platform or the services.</strong>  <br /><br />
 <strong>1.	MODIFICATION TO THE AGREEMENT</strong> <br />
We reserve the right to modify the terms and conditions of this Agreement at any time, effective upon posting the amended terms on this site. If we make changes, we will notify you by, at a minimum, revising the “Last Updated” date at the top of this Agreement. You are responsible for regularly reviewing and choosing to accept the terms of this Agreement. Continued use of the WeHaul Platform or Services after any such changes shall constitute your consent to such changes. If you do not agree to any such changes, you may not use or access the WeHaul Platform or the Services.
<br /><br />
 <strong>2.	ELIGIBILITY</strong> <br />
The WeHaul Platform is available only to, and may only be used by individuals who can form legally binding contracts under applicable laws. Without limiting the foregoing, the WeHaul Platform is not available to children (persons under the age of 18) or Users who have had their User account temporarily or permanently deactivated. By becoming a User, you represent and warrant that you are at least 18 years old and that you have the right, authority and capacity to enter into and abide by the terms and conditions of this Agreement.
<br /><br />
 <strong>3.	PAYMENTS</strong><br />
<strong>3.1</strong>	As a Customer, you agree that any amounts charged upon your order requesting a ride (“Order”) are mandatory and due and payable immediately upon the assignment of a driver to you. Charges include Ride Fees and a commission and other applicable fees (tolls, surcharges, taxes etc). WeHaul has the authority and reserves the right to determine and modify pricing. Charges may vary based on the type of service you request. You are responsible for reviewing the applicable rates and shall be responsible for all Charges incurred under your User account regardless of your awareness of such Charges or the amounts thereof. Applicable Payments are as follows:
<br />
<strong>3.1.1	Trip Fees:</strong>  Consist of a base price and incremental charges based on the duration and distance of your ride. For particularly short trips, minimum trips fees apply. Trip fees may be subject to a multiplier at times of high demand of the Services as determined by the driver. 
<br />
<strong>3.1.2	Cancellation Fee.</strong> In the event that you cancel a ride request on the WeHaul Platform more than 5 minutes after such request is made, you make repeated or frequent cancellations, or fail to action on your order in a timely manner, you agree to pay a “Cancellation Fee” of 20% of trip fee. We may also assess a Cancellation Fee against you if we suspect that you have intentionally cancelled rides to disrupt the WeHaul Platform or the provision of Services.
<br />
<strong>3.1.3	Other Charges.</strong> Other fees, tolls, and surcharges may apply to your trip, which shall be included in your trip fee.
<br /><br />
<strong>3.2		Facilitation of Payments.</strong> All Charges are facilitated through a third-party payment processing service <strong>(Paystack).</strong>  WeHaul may replace its third-party payment processing services without notice to you. Charges shall only be made through the WeHaul Platform. Cash payments are strictly prohibited.
<br />
<strong>3.3		Refunds.</strong> Charges are assessed immediately upon request of the trip. Upon cancellation or any other event warranting a refund, charges are refundable; however, a 20% surcharge may apply. This refund policy shall apply at all times regardless of your decision to terminate usage of the WeHaul Platform, any disruption to the WeHaul Platform or Services, or any other reason whatsoever; save and except refunds occasioned by loss of or damage to transported goods/items.
<br />
<strong>3.4		Promotions.</strong> WeHaul, at its sole discretion, may make available promotions with different features to any of our Customers or prospective Customers. These promotions, unless made to you, shall have no bearing whatsoever on your Agreement or relationship with WeHaul.
<br /><br />
<strong>4.	WEHAUL COMMUNICATIONS</strong><br />
By becoming a User, you expressly consent and agree to accept and receive communications from us, including via e-mail, text message, calls, and push notifications to the cellular telephone number you provided to us. By consenting to being contacted by WeHaul, you understand and agree that you may receive communications generated by automatic telephone dialing systems and/or which will deliver messages sent by or on behalf of WeHaul, its affiliated companies and/or Drivers, including but not limited to: operational communications concerning your User account or use of the WeHaul Platform or Services, updates concerning new and existing features on the WeHaul Platform, communications concerning promotions run by us or our third party partners, and news concerning WeHaul and industry developments. IF YOU WISH TO OPT-OUT OF PROMOTIONAL EMAILS, TEXT MESSAGES, OR OTHER COMMUNICATIONS, YOU MAY OPT-OUT BY FOLLOWING THE UNSUBSCRIBE OPTIONS PROVIDED TO YOU, INCLUDING THE “END” AND “STOPALL” OPTIONS DESCRIBED BELOW. 
<br /><br />
<strong>5.	YOUR INFORMATION</strong><br />
Your information is any information you provide, publish or post to or through the WeHaul Platform (including any profile information you provide) or send to other Users (including via in-application feedback, any email feature, or through any WeHaul-related Facebook, Twitter or other social media posting) (your “Information”). You consent to us using your Information to create a User account that will allow you to use the WeHaul Platform and participate in the Services and any promotions. Our collection and use of personal information in connection with the WeHaul Platform and Services is as provided in WeHaul’s Privacy Policy located on our website at <a href="https://wehaul247.com/privacy_policy" target="_blank" rel="noopener noreferrer">WeHaul Privacy Policy</a> <br /><br />

<strong>6.	PRIVACY</strong><br />
<strong>6.1</strong> 	You are solely responsible for your Information and your interactions with users and other members of the public (“others”), and we act only as a passive conduit for your online posting of your Information. You agree to provide and maintain accurate, current and complete information and that we and other may rely on your Information as accurate, current and complete. You warrant and represent to us that you are the sole author of your Information. 
<br />
<strong>6.2</strong>	WeHaul does not assert any ownership over your information; rather, as between us and you, subject to the rights granted to us in this Agreement, you retain full ownership of all of your Information and any intellectual property rights or other proprietary rights associated with your Information.
<br />
<strong>6.3</strong>	You are the sole authorized user of your account. You are responsible for maintaining the confidentiality of any password provided by you or WeHaul for accessing the WeHaul Platform. You are solely and fully responsible for all activities that occur under your User account and WeHaul expressly disclaims any liability arising from the unauthorized use of your User account. Should you suspect that any unauthorized party may be using your User account or you suspect any other breach of security, you agree to notify us immediately.
<br /><br />

<strong>7.	SOCIAL NETWORKING SITES</strong><br />
As part of the functionality of the WeHaul Platform, you may be able to create or login to your User account through online accounts you may have with third party social networking sites (each such account, an “SNS Account”) by either providing your SNS Account login information through the WeHaul Platform or allowing WeHaul to access your SNS Account. By granting WeHaul access to any SNS Accounts, you understand that WeHaul may access, make available and store any content that you have provided to and stored in your SNS Account including without limitation any friend, mutual friends, contacts or following/followed lists (the “SNS Content”) so that it is available on and through the WeHaul Platform to other Users. Unless otherwise specified in this Agreement, all SNS Content, if any, shall be considered to be your Information. Depending on the privacy settings that you have set in such SNS Accounts, personally identifiable information that you post to your SNS Accounts may be available on and through the WeHaul Platform.
<br /><br />
<strong>8.	RESTRICTED ACTIVITIES</strong><br />
<strong>8.1</strong>	With respect to your use of the WeHaul Platform and your participation in, and use of the Services, you agree that you will not:
<br /><strong>a.</strong> 	impersonate any person or entity;
<br /><strong> b.</strong>	stalk, threaten, or otherwise harass any person, or carry any weapons;
<br /><strong>c.</strong>	violate any law, statute, ordinance or regulation;
<br /><strong>d.</strong>	interfere with or disrupt the Services or the WeHaul Platform or the servers or networks connected to the WeHaul Platform;
<br /><strong>e.</strong>	post information or interact on the WeHaul Platform or Services in a manner which is false, inaccurate, misleading (directly or by omission or failure to update information), defamatory, libelous, abusive, obscene, profane, offensive, sexually oriented, threatening, harassing, or illegal;
<br /><strong>f.</strong>	use the WeHaul Platform in any way that infringes any third party’s rights, including but not limited to: intellectual property rights, copyright, patent, trademark, trade secret or other proprietary rights or rights of publicity or privacy;
<br /><strong>g.</strong>	post, email or otherwise transmit any malicious code, files or programs designed to interrupt, damage, destroy or limit the functionality of any computer software or hardware or telecommunications equipment or surreptitiously intercept or expropriate any system, data or personal information;
<br /><strong>h.</strong>	forge headers or otherwise manipulate identifiers in order to disguise the origin of any information transmitted through the WeHaul Platform;
<br /><strong>i.</strong>	“frame” or “mirror” any part of the WeHaul Platform, without our prior written authorization or use meta tags or code or other devices containing any reference to us in order to direct any person to any other web site for any purpose;
<br /><strong>j.</strong>	modify, adapt, translate, reverse engineer, decipher, decompile or otherwise disassemble any portion of the WeHaul Platform or any software used on or for the WeHaul Platform;
<br /><strong>k.</strong>	rent, lease, lend, sell, redistribute, license or sublicense the WeHaul Platform or access to any portion of the WeHaul Platform;
<br /><strong>l.</strong>	use any robot, spider, site search/retrieval application, or other manual or automatic device or process to retrieve, index, scrape, “data mine”, or in any way reproduce or circumvent the navigational structure or presentation of the WeHaul Platform or its contents;
<br /><strong>m.</strong>	transfer or sell your User account, password and/or identification to any other party;
<br /><strong>n.</strong>	cause any third party to engage in the restricted activities above.

<br /><strong>8.2</strong>	We reserve the right, but we have no obligation, to suspend or deactivate your User account if you do not comply with these prohibitions.
<br /><br /><strong>9. 	DRIVER REPRESENTATIONS AND WARRANTIES TO CUSTOMERS</strong><br />
Drivers on the WeHaul Platform, represent, warrant, and agree that they:
<br /><strong>a.</strong>	possess a valid driver’s license and are authorized and medically fit to operate a motor vehicle and have all appropriate licenses, approvals and authority to provide the service to Customers
<br /><strong>b.</strong>	own, or have the legal right to operate, the vehicle used when providing Services, and such vehicle is in good operating condition and meets the industry safety standards and all applicable statutory and state department of motor vehicle requirements for a vehicle of its kind.
<br /><strong>c.</strong>	will only provide Services using the vehicle that has been reported to, screened and deemed fit for use by WeHaul
<br /><strong>d.</strong>	have a valid policy of vehicle insurance and (in coverage amounts consistent with all applicable legal requirements) that names or schedules the driver for the operation of the vehicle they use to provide Services.
<br /><strong>e.</strong>	will be solely responsible for any and all liability that results from or is alleged as a result of their provision of Services, including, but not limited to death and property damages 
<br /><strong>f.</strong>	In the event of a motor vehicle accident the driver will be solely responsible for compliance with any applicable statutory or VIO or FRSC requirements, and for reporting the accident to WeHaul in a timely manner.
<br /><strong>g.</strong>	will comply with all applicable laws, rules and regulations while providing Services, and you will be solely responsible for any violations of such provisions.
<br /><strong>h.</strong>	will not make any misrepresentation regarding WeHaul, the WeHaul Platform, the Services or their status as a Driver, or, while providing the Services, operate as a public carrier or hailing service, nor accept street hails, charge for rides (except as expressly provided in this Agreement), or engage in any other activity in a manner that is inconsistent with their obligations to WeHaul or to customers under this Agreement.
<br /><strong>i.</strong>	will not discriminate against or harass customers 
<br /><br /><strong>10. PROPRIETARY RIGHTS AND TRADEMARK LICENSE</strong>	<br />

<br /><strong>10.1</strong>	All intellectual property rights in the WeHaul Platform shall be owned by us absolutely and in their entirety. These rights include and are not limited to database rights, copyright, design rights (whether registered or unregistered), trademarks (whether registered or unregistered) and other similar rights wherever existing in the world together with the right to apply for protection of the same. All other trademarks, logos, service marks, company or product names set forth in the WeHaul Platform are the property of their respective owners. 
<br /><strong>10.2</strong>	You acknowledge and agree that any questions, comments, suggestions, ideas, feedback or other information (“Submissions”) provided by you to us are non-confidential and shall become the sole property of WeHaul. WeHaul shall own exclusive rights, including all intellectual property rights, and shall be entitled to the unrestricted use and dissemination of these submissions for any purpose, commercial or otherwise, without acknowledgment or compensation to you.
<br /><strong>10.3</strong>	You acknowledge that WeHaul is the owner and licensor of the WeHaul Trademarks, and that your use of the WeHaul platform will confer no additional interest in or ownership of the WeHaul Trademark in you but rather inures to the benefit of WeHaul. 
<br /><strong>10.4</strong>	If you create any materials bearing the WeHaul Trademarks (in violation of this Agreement or otherwise), you agree that upon their creation WeHaul exclusively owns all right, title and interest in and to such materials, including without limitation any modifications to the WeHaul Trademarks or derivative works based on the WeHaul Trademarks. 
<br /><br /><strong>11.	COPYRIGHT COMPLAINTS AND COPYRIGHT AGENT</strong><br />
WeHaul respects the intellectual property of others, and expects Users to do the same. If you believe, in good faith, that any materials on the Services infringe upon your copyrights, please send the following information to WeHaul at ……….
<br /><br /><strong>12. 	DISCLAIMERS/ LIABILITY</strong><br />
The following disclaimers are made on behalf of WeHaul, our affiliates, and each of our respective officers, directors, employees, agents, shareholders and suppliers:
<br /><strong>12.1</strong>	The WeHaul Platform is provided on an “as is” basis and without any warranty or condition, express, implied or statutory. We do not guarantee and do not promise any specific results from use of the WeHaul Platform and/or the Services, including the ability to provide or receive Services at any given location or time.
<br /><strong>12.2</strong>	We do not warrant that your use of the WeHaul Platform or Services will be accurate, complete, reliable, current, secure, uninterrupted, always available, or error-free, or will always meet your requirements, that any defects in the WeHaul Platform will be corrected, or that the WeHaul Platform is free of viruses or other harmful components. We disclaim liability for, and no warranty is made with respect to, connectivity and availability of the WeHaul Platform or Services.
<br /><strong>12.3</strong>	We have no control over the quality or safety of the transportation that occurs as a result of the Services. We cannot ensure that a user will complete an arranged transportation service or that a vehicle will be entirely fit for purpose.
<br /><strong>12.4</strong>	Users are required to use caution and discretion when using the WeHaul Platform and Services, including looking at the photos of the Driver you have matched with to make sure it is the same individual you see in person. We encourage you to communicate directly with each potential Driver prior to engaging in an arranged transportation service.
	We do not accept responsibility or liability for any content, communication or other use or access of the WeHaul Platform by persons under the age of 18 in violation of this Agreement.
    <br /><strong>12.5</strong>	WeHaul is not responsible for the conduct, whether online or offline, of any User of the WeHaul Platform or Services. You are solely responsible for your interactions with other Users. By using the WeHaul Platform and participating in the Services, you agree to accept such risks and agree that WeHaul is not responsible for your acts or omissions on the WeHaul Platform or that of the drivers.
<br /><strong>12.6</strong>	Due care and diligence is taken in the use of Users information on the WeHaul Platforms. It is however possible for others to obtain information about you that you provide, publish or post to or through the WeHaul Platform (including any profile information you provide), send to other Users, or share during the Services, and to use such information to harass or harm you. We are not responsible for the use of any personal information that you disclose to other Users on the WeHaul Platform or through the Services. Please carefully select the type of information that you post on the WeHaul Platform or through the Services or release to others. We disclaim all liability, regardless of the form of action, for the acts or omissions of other Users (including unauthorized users, or “hackers”).
<br /><strong>12.7</strong>	Opinions, advice, statements, offers, or other information or content made available through the WeHaul Platform, but not directly by us, are those of their respective authors, and should not necessarily be relied upon. Such authors are solely responsible for such content. Under no circumstances will we be responsible for any loss or damage resulting from your reliance on information or other content posted on the WeHaul Platform or otherwise disseminated by third parties. We reserve the right, but we have no obligation, to monitor the materials posted in the public areas of the WeHaul Platform and remove any such material that in our sole opinion violates, or is alleged to violate, the law or this agreement or which might be offensive, illegal, or that might violate the rights, harm, or threaten the safety of Users or others.
<br /><strong>12.8</strong>	The WeHaul Platform contains (or you may be sent through the WeHaul Platform) links to other web sites owned and operated by third parties (“Third Party Sites”), as well as articles, photographs, text, graphics, pictures, designs, music, sound, video, information, applications, software and other content or items belonging to or originating from third parties (“Third Party Content”). Such Third Party Sites and Third Party Content are not investigated, monitored or checked for accuracy, appropriateness, or completeness by us, and we are not responsible for any Third Party Sites or Third Party Content accessed through the WeHaul Platform.
<br /><strong>12.9</strong>	Location data provided by the WeHaul Platform is for basic location purposes only and is not intended to be relied on where erroneous, inaccurate or incomplete or in situations where precise location information is needed. Neither WeHaul, nor any of its content providers, guarantees the availability, accuracy, completeness, reliability, or timeliness of location data displayed by the WeHaul Platform. Any of your information, including geo-locational data, you upload, provide, or post on the WeHaul Platform may be accessible to WeHaul and certain Users of the WeHaul Platform. This applies to any version of the WeHaul Platform that you acquire, use or download. 
<br /><strong>12.91</strong>	This Agreement is entered into between you and WeHaul. Google Play Store and the Apple Store are not parties to this Agreement and shall have no obligations with respect to the WeHaul Platform. WeHaul is solely responsible for the WeHaul Platform and the content thereof as set forth hereunder. 
<br /><br /><strong>13.	DISCLOSURES</strong><br />
You will defend, indemnify, and hold us and our affiliates and each of our respective officers, directors, employees, agents, shareholders and suppliers harmless from any claims, actions, suits, losses, costs, liabilities and expenses (including reasonable attorneys’ fees) relating to or arising out of your use of the WeHaul Platform and participation in the Services, including:
<br /><strong>a.</strong>	Your breach of this Agreement or the documents it incorporates by reference;
<br /><strong>b.</strong>	Your violation of any law or the rights of a third party, including, without limitation, Drivers, Customers, other motorists, and pedestrians, as a result of your own interaction with such third party;
<br /><strong>c.</strong>	Any allegation that any materials that you submit to us or transmit through the WeHaul Platform infringe or otherwise violate any Nigerian Laws, or other rights of any third party;
<br /><strong>d.</strong>	Any other activities in connection with the Services. <br />
<br /><strong>14.	LIMITATION OF LIABILITY</strong>
<br /><strong>14.1</strong>	You hereby represent that you have the title to or have the required authorization to and over any items, goods, commodities, property, merchandise or generally over anything for which you order or require  WeHaul service. In no event will we, the driver, our affiliates, or each of our respective officers, directors, employees, agents, shareholders or suppliers, be liable to you for any incidental, special, punitive, consequential, or indirect damages arising out of or in connection with the use of the WeHaul platform, the services, or this agreement, however arising including out of negligence, theft, or the like.
<br /><strong>14.2</strong>	We will not be liable for any damages, direct, indirect, special, punitive, incidental and/or consequential (including, but not limited to physical damages, bodily injury, death and/or emotional distress and discomfort) arising out of your communicating with or meeting other users of the WeHaul platform or services. 
<br /><br /><strong>15.	RELEASE</strong><br />
In the event that you have a dispute with a driver, you agree to release WeHaul (including our affiliates and each of our respective officers, directors, employees, agents, shareholders, and suppliers) from claims, demands and damages of every kind and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in your use of the WeHaul Platform.
<br /><br /><strong>16.	TERM AND TERMINATION</strong><br />
<strong>16.1</strong>	This Agreement is effective upon your creation of a User account, or as may be amended by any modifications made pursuant to Clause 1. You may discontinue your use of the WeHaul Platform at any time, for any reason. 
<br /><strong>16.2</strong>	We may suspend or deactivate your User account or revoke your permission to access the WeHaul Platform, at any time, for any reason. We reserve the right to refuse access to the WeHaul Platform to any User for any reason not prohibited by law. 
<br /><br /><strong>17. AGREEMENT TO ARBITRATE ALL DISPUTES AND LEGAL CLAIMS</strong><br />	
<strong>17.1</strong>	You and we agree that any legal disputes or claims arising out of or related to this Agreement (including but not limited to the use of the WeHaul Platform and/or the Services, or the interpretation, enforceability, revocability, or validity of the Agreement, or the arbitrability of any dispute), that cannot be resolved informally shall be submitted to binding arbitration in Lagos State. The arbitration shall be conducted by the Citizens Mediation Center of Lagos State, or as otherwise mutually agreed by you and we. Any judgment on the award rendered by the arbitrator may be entered in any court having jurisdiction thereof..
<br /><strong>17.2</strong>	You and we agree that any claim, action or proceeding arising out of or related to the Agreement must be brought in your individual capacity, and not as a plaintiff or class member in any purported class, collective, or representative proceeding. The arbitrator may not consolidate more than one person’s claims, and may not otherwise preside over any form of a representative, collective, or class proceeding.

<br /><br /><strong>18.	CONFIDENTIALITY</strong><br />
You agree:
<br /><strong>18.1</strong>	 not to use any technical, financial, strategic and other proprietary and confidential information relating to WeHaul’s business, operations and properties, including User information (“Confidential Information”) disclosed to you by WeHaul for your own use or for any purpose other than as contemplated herein. 
<br /><strong>18.2</strong>	You shall not disclose or permit disclosure of any Confidential Information to third parties. 
<br /><br /><strong>19.	NOTICES, COMPLAINTS, COMMUNICATION</strong><br />
Except where explicitly stated otherwise, any notices to WeHaul shall be given by 
Electronic mail to hello@wehaul247.com.

Any notices to you shall be provided to you through the WeHaul Platform or given to you via the email address you provide to WeHaul during the registration process, and such notice shall be deemed given immediately upon sending. To resolve a complaint regarding the WeHaul Platform, you should first contact our Customer Care Support on …… or through the contact support option on the WeHaul platform 
<br /><br /><strong>20.	GENERAL</strong><br />
<br /><strong>20.1</strong>	This Agreement shall be governed by the laws Lagos State, Nigeria. If any provision of this Agreement is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions shall be enforced. You agree that this Agreement and all incorporated agreements may be automatically assigned by WeHaul, in our sole discretion in accordance with the “Notices” section of this Agreement. 
<br /><strong>20.2</strong>	Headings are for reference purposes only and in no way define, limit, construe or describe the scope or extent of such section. 
<br /><strong>20.3</strong>	A party’s failure to act with respect to a breach by the other party does not constitute a waiver of the party’s right to act with respect to subsequent or similar breaches. 
<br /><strong>20.4</strong>	This Agreement sets forth the entire understanding and agreement between you and WeHaul with respect to the subject matter hereof.


            </p>
            {/* <br />
            <div style={{borderBottom: "2px solid #113E82"}} className="line"></div> */}
        </div>
    )
}

export default Terms;