import React, { useState } from "react";
import "./pendingTrips.scss";
import { Link } from "react-router-dom";
import TripView from "../../trip-view/tripView";
import { useSelector } from "react-redux";
import EmptyState from "../../../emptyState/emptyState";

const PendingTrips = ({ data }) => {
  const [tripViewShown, setTripViewShown] = useState(false);
  const [tripId, setTripId] = useState("");

  const currentUser = useSelector(({ userData }) => userData.currentUser);
  const {
    name
  } = currentUser.data;


  const getTripId = (id) => {
    console.log(id);
    setTripId(id);
    setTripViewShown(true);
  };

  return (
    <div className="all-trips">
      {data?.length === 0 ? <EmptyState /> : 
      <table>
        <tr className="label-row">
          <th>Pick-up</th>
          {/* <th>Drop-off</th> */}
          <th>Driver</th>
          <th>Status</th>
          <th>Vehicle Type</th>
          <th>Trip Fee</th>
          {/* <th>Customer Name</th> */}
          <th>Pickup Date</th>
          <th>Action</th>
        </tr>
        {data
          .filter((datax) => datax.tripStatus === "pending")
          .map(
            ({ request, acceptedBy, tripFee, tripStatus, status, _id: id }, index) => {
              return (
                <tr className="content-row" key={index}>
                  <td className="lefty pre">{request?.pickUpAddress}</td>
                  {/* <td className="lefty">{request.destinationAddress}</td> */}
                  <td>{acceptedBy?.name}</td>
                  {tripStatus === "pending" && (
                    <td>
                      <p className="pending-status">{tripStatus}</p>
                    </td>
                  )}
                  {tripStatus === "on-going" && (
                    <td>
                      <p className="ongoing-status">{tripStatus}</p>
                    </td>
                  )}
                  {tripStatus === "completed" && (
                    <td>
                      <p className="completed-status">{tripStatus}</p>
                    </td>
                  )}
                  {status === "cancelled" && (
                    <td>
                      <p className="cancelled-status">{tripStatus}</p>
                    </td>
                  )}
                  <td>{request?.vehicleType}</td>
                  <td>{tripFee}</td>
                  {/* <td>{request.receiverDetails.name}</td> */}
                  <td>{request?.pickUpDate}</td>
                  {/* <td></td> */}
                  <td>
                    {tripStatus === "pending" && (
                      <div>
                        <i
                          onClick={() => getTripId(id)}
                          style={{
                            color: "#fff",
                            display: "block",
                            width: "fit-content",
                            margin: "auto",
                            cursor: "pointer",
                            backgroundColor: "#FFBB00",
                            marginBottom: "4px",
                            padding: "3px",
                            borderRadius: "2px",
                          }}
                          className="fas fa-eye"
                        ></i>
                         <a target="_blank" href={"https://wa.me/" + "234" + acceptedBy?.phoneNumber.substring(1) + "?text=Hi, I'm " + name + ". I have a pending delivery of " + request?.requestTitle + ", to be picked up by you from " + request?.pickUpAddress + " and delivered to " + request?.destinationAddress + " on WeHaul247"} data-action="share/whatsapp/share">
                        <i
                          style={{
                            color: "#fff",
                            cursor: "pointer",
                            backgroundColor: "#40D192",
                            padding: "4px",
                            borderRadius: "2px",
                          }}
                          className="far fa-comment-dots"
                        ></i></a>
                      </div>
                    )}
                    {tripStatus === "completed" && (
                      <div>
                        <i
                          onClick={() => setTripViewShown(true)}
                          style={{
                            color: "#fff",
                            cursor: "pointer",
                            backgroundColor: "#FFBB00",
                            marginBottom: "4px",
                            padding: "3px",
                            borderRadius: "2px",
                          }}
                          className="fas fa-eye"
                        ></i>
                         <a target="_blank" href={"https://wa.me/" + "234" + acceptedBy?.phoneNumber.substring(1) + "?text=Hi, I'm " + name + ". I have a pending delivery of " + request?.requestTitle + ", to be picked up by you from " + request?.pickUpAddress + " and delivered to " + request?.destinationAddress + " on WeHaul247"} data-action="share/whatsapp/share">
                        <i
                          style={{
                            color: "#fff",
                            cursor: "pointer",
                            backgroundColor: "#40D192",
                            padding: "4px",
                            borderRadius: "2px",
                          }}
                          className="far fa-comment-dots"
                        ></i></a>
                      </div>
                    )}
                    {tripStatus === "ongoing" && (
                      <div>
                        <i
                          onClick={() => setTripViewShown(true)}
                          style={{
                            color: "#fff",
                            cursor: "pointer",
                            backgroundColor: "#FFBB00",
                            marginBottom: "4px",
                            padding: "3px",
                            borderRadius: "2px",
                          }}
                          className="fas fa-eye"
                        ></i>
                         <a target="_blank" href={"https://wa.me/" + "234" + acceptedBy?.phoneNumber.substring(1) + "?text=Hi, I'm " + name + ". I have a pending delivery of " + request?.requestTitle + ", to be picked up by you from " + request?.pickUpAddress + " and delivered to " + request?.destinationAddress + " on WeHaul247"} data-action="share/whatsapp/share">
                        <i
                          style={{
                            color: "#fff",
                            cursor: "pointer",
                            backgroundColor: "#40D192",
                            padding: "4px",
                            borderRadius: "2px",
                          }}
                          className="far fa-comment-dots"
                        ></i></a>
                      </div>
                    )}
                    {tripStatus === "cancelled" && (
                      <div>
                        <i
                          onClick={() => setTripViewShown(true)}
                          style={{
                            color: "#fff",
                            cursor: "pointer",
                            backgroundColor: "#113E82",
                            padding: "3px",
                            borderRadius: "2px",
                          }}
                          className="fas fa-eye"
                        ></i>
                      </div>
                    )}
                  </td>
                </tr>
              );
            }
          )}
      </table>}
      {tripViewShown && <TripView tripId={tripId} setTripViewShown={setTripViewShown} />}
    </div>
  );
};

export default PendingTrips;
