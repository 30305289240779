import React,{ useState} from "react";
import { useQuery } from "react-query";
import { getAllRequests } from "../../../../services/requestServices";
import DeleteModal from "../../../deleteModal/deleteModal";
import EmptyState from "../../../emptyState/emptyState";

const CancelledRequests = () => {
 

  const { data, error, isLoading } = useQuery("getTrips", getAllRequests);
  if (isLoading) {
    console.log("data is loading");
  } else if (data) {
    console.log("getRequests", data.data.data);
  } else {
    console.log(error);
  }
  return (
    <div className="all-requests">
      {data?.data?.data.length === 0 ? (
        <EmptyState />
      ) : (
        <table>
          <tr className="label-row">
            <th>Pick-up</th>
            <th>Drop-off</th>
            <th>Vehicle</th>
            <th>Receiver</th>
            <th>Pickup Date</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
          {data?.data?.data
            .filter((data) => data.requestStatus === "cancelled")
            .map(
              (
                {
                  pickUpAddress,
                  destinationAddress,
                  vehicleType,
                  goodDesc,
                  receiverDetails,
                  pickUpDate,
                  requestStatus,
                  estPrice,
                },
                index
              ) => {
                return (
                  <tr className="content-row" key={index}>
                    <td className="lefty pre">{pickUpAddress}</td>
                    <td className="lefty">{destinationAddress}</td>
                    <td>{vehicleType}</td>
                    <td>{receiverDetails.name}</td>
                    <td>{pickUpDate}</td>
                    {requestStatus === "active" && (
                      <td>
                        <p className="active-status">{requestStatus}</p>
                      </td>
                    )}
                    {requestStatus === "cancelled" && (
                      <td>
                        <p className="cancelled-status">{requestStatus}</p>
                      </td>
                    )}
                    <td>
                    <p style={{ backgroundColor: "#113E82", color: "#fff", padding: "5px", borderRadius: "6px", marginBottom: "7px" }}>View</p>
                      {/* <div>
                        <i
                          style={{ backgroundColor: "#37AB78" }}
                          className="far fa-comment-dots"
                        ></i>
                      </div> */}
                    </td>
                  </tr>
                );
              }
            )}
        </table>
      )}
      
    </div>
  );
};

export default CancelledRequests;
