import { Avatar, Badge, Box, Button, Divider, Flex, FormControl, FormLabel, HStack, Image, Input, InputGroup, InputRightElement, Spinner, Text, useDisclosure } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import Logo from '../../assets/wehaulogo.png';
import './test.scss';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
  } from '@chakra-ui/react'
import { useEffect, useRef, useState } from "react";
import { userActionTypes } from "../../redux/constants/userActionTypes";
import axios from "axios";
import { toast } from "react-toastify";
import { baseUrl, devUrl } from '../../config.json';
import Navbar from "../../navbar/navbar";
// import { ArrowDownIcon, CheckCircleIcon } from "@chakra-ui/icons";
import moment from "moment";
import Check from './check.svg';
import LiveTracking from "./live-tracking/liveTracking";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const config = {
  headers: {
     "Authorization": `Bearer ${localStorage.WEHAUL_CUSTOMER_TOKEN}`
     }
}

const GuestRequest = (props) => {
    const currentUser = useSelector(({ userData }) => userData.currentUser);
    const { isOpen, onOpen, onClose } = useDisclosure()
    console.log("gutty", currentUser);

    const [details, setDetails] = useState(null);
    const [ploading, setPloading] = useState(true)

    const query = new URLSearchParams(props.location.search);
    const tripId = query.get('tripId')
    console.log("location", tripId)

    useEffect(() => {
      if(localStorage.WEHAUL_CUSTOMER_TOKEN){
        axios
          .get(`${baseUrl}/customer/trip/${tripId}/trip-details`, config)
          // .then((res) => console.log(res?.data?.data))
          .then((res) => setDetails(res?.data?.data))
          .finally((res) => setPloading(false))
          .catch((err) => toast.error("Trip not found!"));
      }
      }, []);

    const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCpassword] = useState("");

  const [fullname, setFullname] = useState("");
  const [phone, setPhone] = useState("");
  const [email2, setEmail2] = useState("");
  const [password2, setPassword2] = useState("");
  const [cpassword2, setCpassword2] = useState("");

    const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();

    const [isLogin, setIsLogin] = useState(true);
  
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [loading, setLoading] = useState(true)
    const [historyx, setHistoryx] = useState([])

    const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

    useEffect(() => {
        if(!currentUser) {
            onOpen()
        }
    }, []);

    const dispatch = useDispatch();

    const reqID = ""

    const trackRequest = async () => {
    
      try {
          const res = await axios.get(`https://app.wehaul247.com/api/v1/tracking/track/634ec7d31f3a1b0012ebf041`)
          console.log("tracking history", res?.data?.data)
          setHistoryx(res?.data?.data)
          // onClose()
          // onOpen2();
      } catch(error) {
          console.log(error?.response?.data?.message)
          toast.error(error?.response?.data?.message)
      }
      finally {
        setLoading(false);
      }
    }

    useEffect(() => {
    trackRequest()
    }, [])

    const formData = {
        email,
        password
    }

    const login = async (e) => {
        e.preventDefault();
        setLoading1(true)
         try {
            const { data } = await axios.post(`${baseUrl}/auth/customer/login`, formData);
            console.log(data, data.token)
            setLoading1(false)
            localStorage.setItem("WEHAUL_CUSTOMER_TOKEN", data.token);
            dispatch({ type: userActionTypes.SIGNIN_SUCCESS, payload: data });
            // onClose()
            window.location.reload();
         } catch (error) {
            console.log(error?.response?.data?.message)
            toast.error(error?.response?.data?.message, {
               position: toast.POSITION.TOP_CENTER
            })
            setLoading1(false)
         }
      }

      const inputRef = useRef(null);
      const printDocument = () => {
        html2canvas(inputRef.current).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF("p", "pt", "a4");
          pdf.addImage(imgData, "JPEG", 0, 0);
          pdf.save("wehaul-trip.pdf");
        });
      };

      const history = useHistory()


      const formData2 = {
        "name": fullname,
        "phoneNumber": phone,
        "whatsAppNumber" : phone,
        "country": "nigeria",
        "email": email2,
        "password": password2,
        "userClass": 'customer'
  }

  const handleSubmit = async e => {
    e.preventDefault();
  
        setLoading(true)
        try {
            const { data } = await axios.post(`${baseUrl}/auth/sign-up`, formData2)
            setLoading(false)
            toast.success(data?.message, {
              position: toast.POSITION.TOP_CENTER
            })
            setIsLogin(true)
            // onClose()
            // history.push("/")
         } catch (error) {
          setLoading(false)
            toast.error(error?.response?.data?.message, {
                position: toast.POSITION.TOP_CENTER
            })
         }
      } 
    // else {
    //     toast.error('password mismatch', {
    //         position: toast.POSITION.TOP_CENTER
    //     })
    //     setLoading(false)
    // }

    // else if(number.length < 11) {
    //     toast.error('invalid phone number', {
    //         position: toast.POSITION.TOP_CENTER
    //     })
    // }


    return (
        <Box bg="#123870" paddingBottom={"3rem"}>
          <Navbar />
            {ploading ? 
                  <Flex justifyContent={"center"} marginTop="8rem">
                      <Spinner size={"lg"} color="#fff" />
                  </Flex>
                  :
                  <>
                  <Flex flexWrap={"wrap"} mt={6}  pl={{base: "1rem", md: "2rem"}} pr={{base: "1rem", md: "2rem"}} gap="1rem" justifyContent={"space-between"}>

                  <Box ref={inputRef} fontSize={".9rem"} flex="2" padding={{base: ".3rem", md:"1.5rem"}} borderRadius={"10px"} bg="#fff" border="1px solid #eee" minHeight={"650px"}>
                    <Box mb={3} borderRadius="2px" height={"10px"} bg="#F2D399"></Box>
                    <Box>
                        <Box textAlign={"center"}>
                          <Text fontSize={".8rem"} color="gray">Request For</Text>
                          <Text fontWeight={"500"}>{details?.request?.requestTitle}</Text>
                        </Box>
                      
                        <Flex mt={3} justifyContent="center">
                          <Box w={"45%"} border="1px solid lightgray" borderRadius={"10px"} padding={"1rem"} textAlign={"center"}>
                            <Text fontSize={".8rem"} color="gray">Pick-up Address</Text>
                            <Text fontWeight={"500"}>{details?.request?.pickUpAddress}</Text>
                          </Box>
                          <Box marginTop={"45px"} width={"30px"} borderTop="1px solid lightgray"></Box>
                          <Box width={"45%"} border="1px solid lightgray" borderRadius={"10px"} padding={"1rem"} textAlign={"center"}>
                            <Text fontSize={".8rem"} color="gray">Delivery Address</Text>
                            <Text fontWeight={"500"}>{details?.request?.destinationAddress}</Text>
                          </Box>
                        </Flex>
                        <Flex pb=".4rem" borderBottom="1px solid lightgray" mt={3} justifyContent="center">
                          <Box>
                            <Box w={"100%"} padding={".4rem"} textAlign={"center"}>
                              <Text fontSize={".8rem"} color="gray">Pickup Contact Name</Text>
                              <Text fontWeight={"500"}>{details?.request?.createdBy?.name}</Text>
                            </Box>
                            <Box width={"100%"} padding={".4rem"} textAlign={"center"}>
                              <Text fontSize={".8rem"} color="gray">Pickup Contact Number</Text>
                              <Text fontWeight={"500"}>{details?.request?.createdBy?.phoneNumber}</Text>
                            </Box>
                          </Box>
                          <Box marginTop={"45px"} width={"100px"}></Box>
                          <Box>
                            <Box w={"100%"} padding={".4rem"} textAlign={"center"}>
                              <Text fontSize={".8rem"} color="gray">Receiver's Name</Text>
                              <Text fontWeight={"500"}>{details?.request?.receiverDetails?.name}</Text>
                            </Box>
                            <Box width={"100%"} padding={".4rem"} textAlign={"center"}>
                              <Text fontSize={".8rem"} color="gray">Receiver's Number</Text>
                              <Text fontWeight={"500"}>{details?.request?.receiverDetails?.phone}</Text>
                            </Box>
                          </Box>
                        </Flex>
                        <Flex mt={3} justifyContent="center">
                          <Box w={"30%"} borderRadius={"10px"} padding={"1rem"} textAlign={"center"}>
                            <Text fontSize={".8rem"} color="gray">Pick-up Date</Text>
                            <Text fontWeight={"500"}>{details?.request?.pickUpDate}</Text>
                          </Box>
                          {/* <Box marginTop={"45px"} width={"100px"} borderTop="1px solid lightgray"></Box> */}
                          <Box width={"30%"} borderRadius={"10px"} padding={"1rem"} textAlign={"center"}>
                            <Text fontSize={".8rem"} color="gray">Pick-up Time</Text>
                            <Text fontWeight={"500"}>{details?.request?.pickUpTime}</Text>
                          </Box>
                          {/* <Box marginTop={"45px"} width={"100px"} borderTop="1px solid lightgray"></Box> */}
                          <Box width={"30%"} borderRadius={"10px"} padding={"1rem"} textAlign={"center"}>
                            <Text fontSize={".8rem"} color="gray">Vehicle Type</Text>
                            <Text fontWeight={"500"}>{details?.request?.vehicleType}</Text>
                          </Box>
                        </Flex>
                        <Flex mb={6} justifyContent={"center"}>
                          <Box width={"30%"} border="1px solid lightgray" borderRadius={"10px"} padding={".4rem"} textAlign={"center"}>
                            <Text fontSize={".8rem"} color="gray">Price</Text>
                            <Text fontWeight={"500"}>₦{details?.FeeWithCommission.toLocaleString()}</Text>
                          </Box>
                        </Flex>
                        <Box width={{base: "100%", md: "70%"}} border="1px solid lightgray" padding={".6rem"} borderRadius={"10px"} margin="0 auto" alignItems={"center"} justifyContent={"center"}>
                          <Text textAlign={"center"} fontSize={".8rem"} color="gray">Driver Information</Text>
                          <Flex alignItems={"center"} wrap="wrap" justifyContent="space-between">
                            <Flex padding={"1rem"}>
                              <Avatar mr={3} src={details?.acceptedBy?.profilePicture} name={details?.acceptedBy?.name} />
                              <Box>
                                <Text fontSize={".8rem"} fontWeight={"500"}>{details?.acceptedBy?.name}</Text>
                                <Text color="gray">{details?.acceptedBy?.email}</Text>
                              </Box>
                            </Flex>
                            <Text fontWeight={"500"}>{details?.acceptedBy?.phoneNumber}</Text>
                          </Flex>
                          
                        </Box>
                    </Box>

                    {/*<Text mb={3}>Item Description: <strong style={{color: "#113E82"}}>{details?.request?.goodsDescription}</strong></Text>*/}
                   
                </Box>

                <Box flex={"1"}>

                <Box padding={".7rem 1rem"} bg="#1A59B9" mb="1rem" borderRadius={"10px"} height={"133px"}>
                  <Flex color={"#78bbff"} alignItems={"center"} justifyContent="space-between" fontSize={".75rem"}>
                    <Text>Request ID:</Text>
                    <Text>October 18th 2022</Text>
                  </Flex>
                  <Flex mt={4} color={"#78bbff"} alignItems={"center"} fontSize={".75rem"}>
                    <Text whiteSpace={"nowrap"} mr=".8rem">Trip Status: </Text>
                    <Text fontWeight={"500"} color="#fff" textAlign={"center"} width={"100%"} padding={".3rem .5rem"} borderRadius="6px" bg="#26e726">{details?.tripStatus}</Text>
                  </Flex>
                  <Button onClick={printDocument} _hover={{color: "#000", background: "#eee"}} transition="250ms ease" bg="#123870" mt={4} color="#fff" fontSize={".8rem"} height="30px" width={"100%"} fontWeight="500">Download Trip Details</Button>
                </Box>

                <Box overflow={"hidden"} position={"relative"} borderRadius={"10px"}
                // backgroundImage="https://i.pinimg.com/originals/c8/26/41/c8264172074eb50241381061719391fa.gif"
                // backgroundRepeat={"no-repeat"} backgroundSize="contain"
                // backgroundImage="https://blog.wehaul247.com/wp-content/uploads/2022/06/wehaul-247-van-delivery-1920x1000.jpg"
                // backgroundSize="cover" 
                // flex="1" height={"650px"}
                >
                      <LiveTracking location={details?.trackingDetails?.location} />
                      <Box borderRadius={"15px 15px 0 0 "} padding={"1.5rem 1rem 1.2rem 1rem"} position={"absolute"} width="100%" bottom={"0"} height="fit-content" bg="rgba(255, 255, 255, .95)">
                      {historyx?.trackStages?.map(({stageTitle, date}, index) => {
                        return (
                            <Flex bg="rgba(0, 160, 0, .15)" padding={".3rem .4rem"} borderRadius=".6rem" mb={3} key={index} alignItems={"center"} justifyContent="space-between">
                              <Flex alignItems={"center"}>
                                <Image mr={1} width={"11%"} src={Check} />
                                <Text fontSize={".85rem"} fontWeight="500">{stageTitle}</Text>
                              </Flex>
                              <Text ml={8} textAlign="right" maxW="120px" fontSize={".7rem"}>{moment(date).format('MMMM Do YYYY, h:mm:ss a')}</Text>
                            </Flex>
                        )
                      })}
                      </Box>
                      
                </Box>
                </Box>
            </Flex>
            </>}

            


            <Modal isCentered closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay bg="#000000e9" />
                <ModalContent>
                {isLogin ? (
            <>
              {/* <img style={{width: "30%", margin: "0 auto", paddingTop: ".5rem"}} src={Logo} alt="get_codes_logo" /> */}
              <ModalHeader fontSize="24px">Sign In</ModalHeader>
              <Text mt={-5} ml={6} fontSize="13px" color="gray.400">
                Sign in to your wehaul account.
              </Text>
              {/* <ModalCloseButton /> */}

              <form onSubmit={login}>
                <ModalBody>
                  <FormControl mb={4}>
                    <FormLabel transform="translateY(8px)">
                      Email Address
                    </FormLabel>
                    <Input
                      onChange={(e) => setEmail(e.target.value)}
                      _focus={{ boxShadow: "none" }}
                      size="lg"
                      type="text"
                      fontSize="md"
                      placeholder="Enter email"
                      color="gray.500"
                      _placeholder={{ color: "#718096" }}
                    />
                  </FormControl>
                  <FormControl mb={4}>
                    <FormLabel transform="translateY(8px)">Password</FormLabel>
                    <InputGroup>
                      <Input
                        onChange={(e) => setPassword(e.target.value)}
                        _focus={{ boxShadow: "none" }}
                        size="lg"
                        type={show ? "text" : "password"}
                        fontSize="md"
                        placeholder="Enter password"
                        color="gray.500"
                        _placeholder={{ color: "#718096" }}
                      />
                      <InputRightElement marginTop="5px" width="4.5rem">
                        <Button h="1.75rem" size="sm" onClick={handleClick}>
                          {show ? "Hide" : "Show"}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>

                  <HStack>
                    <Text fontSize="14px">Don't Have an Account ? </Text>
                    <Text
                      onClick={() => setIsLogin(false)}
                      cursor="pointer"
                      fontSize="14px"
                      fontWeight="600"
                      color="#009942"
                    >
                      Sign Up
                    </Text>
                  </HStack>
                </ModalBody>

                <ModalFooter>
                  <Button variant="ghost" mr={3} onClick={onClose}>
                    Close
                  </Button>
                  <Button type="submit" colorScheme="green">
                    {loading1 ? <Spinner /> : "Sign In"}
                  </Button>
                </ModalFooter>
              </form>
            </>
          ) : (
            <>
              {/* <img style={{width: "30%", margin: "0 auto", paddingTop: ".5rem"}} src={Logo} alt="get_codes_logo" /> */}
              <ModalHeader fontSize="24px">Create Account</ModalHeader>
              <Text mt={-5} ml={6} fontSize="13px" color="gray.400">
                Sign up for a wehaul account.
              </Text>
              {/* <ModalCloseButton /> */}

              <form onSubmit={handleSubmit}>
                <ModalBody>
                  <FormControl mb={3}>
                    <FormLabel transform="translateY(8px)">Full Name</FormLabel>
                    <Input
                      onChange={(e) => setFullname(e.target.value)}
                      _focus={{ boxShadow: "none" }}
                      size="lg"
                      type="text"
                      fontSize="md"
                      placeholder="Enter name"
                      color="gray.500"
                      _placeholder={{ color: "#718096" }}
                    />
                  </FormControl>
                  <FormControl mb={3}>
                    <FormLabel transform="translateY(8px)">
                      Email Address
                    </FormLabel>
                    <Input
                      onChange={(e) => setEmail2(e.target.value)}
                      _focus={{ boxShadow: "none" }}
                      size="lg"
                      type="email"
                      fontSize="md"
                      placeholder="Enter email"
                      color="gray.500"
                      _placeholder={{ color: "#718096" }}
                    />
                  </FormControl>
                  <FormControl mb={3}>
                    <FormLabel transform="translateY(8px)">
                      Phone Number
                    </FormLabel>
                    <Input
                      onChange={(e) => setPhone(e.target.value)}
                      _focus={{ boxShadow: "none" }}
                      size="lg"
                      type="text"
                      fontSize="md"
                      placeholder="Enter phone"
                      minLength={11}
                      maxLength={11}
                      color="gray.500"
                      _placeholder={{ color: "#718096" }}
                    />
                  </FormControl>
                  <FormControl mb={3}>
                    <FormLabel transform="translateY(8px)">Password</FormLabel>
                    <InputGroup>
                      <Input
                        onChange={(e) => setPassword2(e.target.value)}
                        _focus={{ boxShadow: "none" }}
                        size="lg"
                        type={show ? "text" : "password"}
                        fontSize="md"
                        placeholder="Enter password"
                        color="gray.500"
                        _placeholder={{ color: "#718096" }}
                      />
                      <InputRightElement marginTop="5px" width="4.5rem">
                        <Button h="1.75rem" size="sm" onClick={handleClick}>
                          {show ? "Hide" : "Show"}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                  {/* <FormControl mb={4}>
                    <FormLabel transform="translateY(8px)">
                      Confirm Password
                    </FormLabel>
                    <InputGroup>
                      <Input
                        onChange={(e) => setCpassword2(e.target.value)}
                        _focus={{ boxShadow: "none" }}
                        size="lg"
                        type={show ? "text" : "password"}
                        fontSize="md"
                        placeholder="Re-Enter password"
                        color="gray.500"
                        _placeholder={{ color: "#718096" }}
                      />
                      <InputRightElement marginTop="5px" width="4.5rem">
                        <Button h="1.75rem" size="sm" onClick={handleClick}>
                          {show ? "Hide" : "Show"}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl> */}

                  <HStack>
                    <Text fontSize="14px">Have an Account Already ? </Text>
                    <Text
                      onClick={() => setIsLogin(true)}
                      cursor="pointer"
                      fontSize="14px"
                      fontWeight="600"
                      color="#009942"
                    >
                      Sign In
                    </Text>
                  </HStack>
                </ModalBody>

                <ModalFooter>
                  <Button variant="ghost" mr={3} onClick={onClose}>
                    Close
                  </Button>
                  <Button type="submit" colorScheme="green">
                    {loading ? <Spinner /> : "Sign Up"}
                  </Button>
                </ModalFooter>
              </form>
            </>
          )}
                </ModalContent>
            </Modal>
        </Box>
    )
}

export default GuestRequest;