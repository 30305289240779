import React from "react";
import "./tripView.scss";
import Avatar from "../../header/avatar.svg";
import axios from "axios";
import { baseUrl } from '../../../config.json'
import RippleLoader from "../../rippleLoader/rippleLoader";

class TripView extends React.Component {
    state = {
        tripId: this.props.tripId,
        setTripViewShown: this.props.setTripViewShown,
        loading: true,
        tripData: {
            acceptedBy: '',
            request: '',
            tripFee: '',
            tripStatus: ''
        }
    }

    componentDidMount() {
        const config = {
            headers: {
               "Authorization": `Bearer ${localStorage.WEHAUL_CUSTOMER_TOKEN}`
               }
         }
         axios.get(`${baseUrl}/customer/trip/${this.state.tripId}/trip-details`, config)
         .then(res => this.setState({tripData: res.data.data, loading: false}))
        //  .then(res => console.log("details", res))
         .catch(err => console.log(err))
    }
  render() {
          const { acceptedBy, request, tripFee, FeeWithCommission, tripStatus, assignedDriver} = this.state.tripData
  
    return (
      <div className="trip-view-page">
          {this.state.loading ? <RippleLoader /> : 
          <div className="vee">
              <p className="top-head">Trips</p>
          <div className="trip-view">
            <div className="top-area">
              <div className="user-deet">
                <div className="left-side">
                  <div className="img" style={{backgroundImage: `url(${acceptedBy?.profilePicture})`}}></div>
                  <div className="name-date">
                    <p className="driver-name">
                      <i className="far fa-user-circle"></i>{acceptedBy?.name}
                    </p>
                    <p className="date">
                      <i className="far fa-clock"></i>{request?.pickUpDate}
                    </p>
                  </div>
                </div>
                <div className="right-side">
                  <p className="status-label">status</p>
                  {tripStatus === 'pending' && <p style={{backgroundColor: '#FFBB00'}} className="status">{tripStatus}</p>}
                  {tripStatus === 'completed' && <p style={{backgroundColor: '#40D192'}} className="status">{tripStatus}</p>}
                  {tripStatus === 'on-going' && <p style={{backgroundColor: '#113E82'}} className="status">{tripStatus}</p>}
                  {tripStatus === 'cancelled' && <p style={{backgroundColor: '#FF1B00'}} className="status">{tripStatus}</p>}
                  
                </div>
              </div>
              <p className="title">Request Information</p>
            </div>
  
            <div className="middle-area">
              <div className="req-info">
                <div className="tag">
                  <p>Pickup Point (Origin)</p>
                  <p>Destination</p>
                  <p>Vehicle Type</p>
                  <p>Pickup route</p>
                  <p>Destination route</p>
                  <p>Receiver's Name</p>
                  <p>Pickup Date</p>
                  <p>Driver's Fee</p>
                  <p>Request Title</p>
                  <p>Request Description</p>
                </div>
                <div className="val">
                  <p>
                    <i className="fas fa-map-marker-alt"></i>{request?.pickUpAddress}
                  </p>
                  <p>
                    <i className="fas fa-location-arrow"></i>{request?.destinationAddress}
                  </p>
                  <p>
                    {request?.vehicleType === 'truck' && <i className="fas fa-truck"></i>}
                    {request?.vehicleType === 'van' && <i className="fas fa-truck"></i>}
                    {request?.vehicleType === 'lorry' && <i className="fas fa-truck"></i>}
                    {request?.vehicleType === 'bike' && <i className="fas fa-biking"></i>}
                    {request?.vehicleType}
                  </p>
                  <p>
                    <i className="far fa-calendar-alt"></i>{request?.pickUpRoute}
                  </p>
                  <p>
                    <i className="fas fa-map-marker-alt"></i>{request?.destinationRoute}
                  </p>
                  <p>
                    <i className="fas fa-user"></i>{request?.receiverDetails.name}
                  </p>
                  <p>
                    <i className="far fa-calendar-alt"></i>{request?.pickUpDate}
                  </p>
                  <p>
                    <i className="fas fa-money-bill"></i>{FeeWithCommission}
                  </p>
                  <p id="light">
                    <i
                      style={{ fontWeight: "600" }}
                      className="far fa-file-alt"
                    ></i>
                   {request?.requestTitle}
                  </p>
                  <p id="light">
                    <i
                      style={{ fontWeight: "600" }}
                      className="far fa-file-alt"
                    ></i>
                   {request?.goodsDescription}
                  </p>
                </div>
              </div>
              <p className="title">Driver's Information</p>
            </div>
  
            <div className="bottom-area">
              <div className="driver-infox">
                <div className="img" style={{backgroundImage: `url(${assignedDriver?.profilePicture})`}}></div>
                <div className="txt">
                  <p className="top">{assignedDriver?.name}</p>
                  <a href={'tel:' + assignedDriver?.phoneNumber}><p className="bottom">{assignedDriver?.phoneNumber}</p></a>
                </div>
                {/* <div className="tag">
                  <p>Driver Name</p>
                  <p>Phone Number</p>
                </div>
                <div className="val">
                  <p>
                    <i className="fas fa-user"></i>{acceptedBy.name}
                  </p>
                  <p>
                    <i className="fas fa-mobile-alt"></i>{acceptedBy.phoneNumber}
                  </p> */}
                  {/* <p>
                    <i className="far fa-envelope"></i>
                  </p> */}
                {/* </div> */}
              </div>
              {/* <button onClick={() => this.state.setTripViewShown(false)}>Confirm</button> */}
            </div>

            <div className="middle-area">
               <p className="title">Enterprise Information</p>
            </div>
           
            <div className="bottom-area">
              <div className="driver-info">
                <div className="tag">
                  <p>Enterprise Name</p>
                  <p>Phone Number</p>
                </div>
                <div className="val">
                  <p>
                    <i className="fas fa-user"></i>{acceptedBy?.name}
                  </p>
                  <a href={'tel:' + assignedDriver?.phoneNumber}>
                    <p>
                    <i className="fas fa-mobile-alt"></i>{acceptedBy?.phoneNumber}
                  </p>
                  </a>
                  {/* <p>
                    <i className="far fa-envelope"></i>
                  </p> */}
                </div>
              </div>
              <button onClick={() => this.state.setTripViewShown(false)}>Close</button>
            </div>
          </div>
          </div>
          
          }
        
      </div>
    );
  }
}

export default TripView;
