import React from 'react';
import './requestVehicle.scss';
import RequestIcon from '../../../assets/requestVehicle.svg';
import { Link } from 'react-router-dom';

const RequestVehicle = () => {
    return ( 
        <Link to='/dashboard/request' style={{textDecoration: 'none'}}>
        <div className="request-vehicle">
            <img src={RequestIcon} alt=" request icon" />
            <p>Request a Vehicle</p>
        </div>
        </Link>
        
     );
}
 
export default RequestVehicle;