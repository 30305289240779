import React from 'react';
import StripePayment from '../stripePayment/stripe';
import './dashboardLanding.scss';
import Overview from './overview/overview';
import PendingTrips from './pending-trips/pendingTrips';
import RequestVehicle from './request-vehicle/requestVehicle';
import TripActivity from './trip-activity/tripActivity';
import TripsChart from './trips-chart/tripsChart';
import Wallet from './wallet/wallet';

const DashboardLanding = () => {
    return ( 
        <div className="dashboard-landing">
           
            <Overview />
            <div className="flex-div">
                <div className="trips-chart">
                    <TripsChart />
                </div>
                <div className="request-wallet">
                      <RequestVehicle />
                      <Wallet />
                </div>
            </div>
            <div className="bottom-secs">
                <div className="trip-activity">
                   <TripActivity />
                </div>
                <div className="pending-trips">
                   <PendingTrips />
                </div>
            </div>
        </div>
     );
}
 
export default DashboardLanding;