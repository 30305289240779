import React, {useEffect, useState} from 'react';
import './notifications.scss';
import Notifs from '../../static/notifs';
import {baseUrl} from '../../config.json';
import axios from 'axios';
import LineLoader from '../lineLoader/lineLoader';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import BattonLoader from './buttonLoader copy/buttonLoader';


const config = {
    headers: {
       "Authorization": `Bearer ${localStorage.WEHAUL_CUSTOMER_TOKEN}`
       }
 }

const Notifications = () => {
    const [notifs, setNotifs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(false);
    const [clicked, setClicked] = useState('')
    const history = useHistory()

//     const currentUser = useSelector(({ userData }) => userData.currentUser);
//   console.log(currentUser.data);

//   const { vehicleInformationAndLicense, isVerified } = currentUser.data;
//   const vehicleType = vehicleInformationAndLicense?.vehicleType;

    useEffect(() => {
        (async () => {
             try {
                 const {data} = await axios.get(`${baseUrl}/notifications`, config)
                 console.log(data)
                 setNotifs(data?.data)
                 setLoading(false)
             } catch (error) {
                 console.log(error?.response)
             }
        })()
     }, [])

     const markNotifAsRead = async (id) => {
        if(clicked === id) {
            return setClicked(null)
          }
          setClicked(id)
          const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.WEHAUL_CUSTOMER_TOKEN}` }
            
        };
        try {
            await fetch(`${baseUrl}/notifications/${id}/mark-as-opened`, requestOptions)
            .then(res => res.json())
            .then(res => console.log(res))
            .then(res => setLoading2(false))
            .then(res => history.push('/dashboard/request'))
        } catch (error) {
            toast.error(error?.response?.data?.message)
        }
        
    }

    return ( 
        <div className="notifications">
            {loading ? <LineLoader /> :
            <>
            <div className="top-head">
               <i className="fas fa-home"></i><p className="top-head-left">Notifications</p>
            </div>
            <div className="highlight">You have {notifs.filter(n => n.isOpened === false).length} new {notifs.filter(n => n.isOpened === false).length > 1 ? "notifications" : "notification"}</div>
            <div className="notifs">
                {notifs.map(({ description, entityId, isOpened, _id: id, notificationType }, index) => {
                    return (
                        <div onClick={() => markNotifAsRead(id)} className={isOpened ? "item read" : "item"} key={id}>
                            <div className="icon">
                              <i style={{color: '#40D192'}} className="fas fa-dot-circle"></i>
                              {/* {type === 'info' && <i style={{color: '#2963B9'}} className="fas fa-info-circle"></i>}
                              {type === 'recommendation' && <i style={{borderRadius: '50%', backgroundColor: '#FFBB00', color: '#FFF'}} className="far fa-smile"></i>}
                              {type === 'warning' && <i style={{color: '#fa3d03'}} className="fas fa-exclamation-circle"></i>} */}
                            </div>
                            <div className="cont-body">
                                <p className="type">{description}</p>
                                {clicked === id ? <BattonLoader/> : !isOpened && <p className="text">NEW!</p>}
                            </div>
                        </div>
                    )
                })}
            </div>
            </>}
        </div>
     );
}
 
export default Notifications;