import React, { useState } from "react";
import ButtonLoader from "../../../components/buttonLoader/buttonLoader";
import "./resetForm.scss";
import {Link} from 'react-router-dom';
import Logo from '../../../assets/logo.svg'
import axios from "axios";
import {baseUrl, herokuBaseUrl} from '../../../config.json';
import { toast } from "react-toastify";

const ResetForm = (props) => {
    const [password, setPassword] = useState("");
    const [cpassword, setCPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const resetToken = props.match.params.resetToken;

    const datax = {
        password
    }


    const reset = async (e) => {
        e.preventDefault();
        setLoading(true)
        if(password === cpassword) {
        try {
            const {data} = await axios.post(`${herokuBaseUrl}/auth/customer/reset-password/${resetToken}`, datax)
            console.log(data)
            toast.success(data.message)
            setLoading(false)
            window.location.href = '/'
        } catch (error) {
            toast.error(error?.response?.data?.message)
            setLoading(false)
        }
      } else {
          toast.error('password mismatch, try again')
      }
    }

  return (
    <div className="password-reset">
      <div className="container">
        <div className="left"></div>
        <div className="right">
          <form onSubmit={reset}>
            <img id="logo" src={Logo} alt="wehaulogo" />
            <h3>Reset Your Password</h3>
            <input
              type="text"
              placeholder="Enter New Password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />{" "}
            <br />
            <input
              type="text"
              placeholder="Confirm New Password"
              required
              value={cpassword}
              onChange={(e) => setCPassword(e.target.value)}
            />{" "}
            <br />
            <button type="submit">{loading && <ButtonLoader />} Reset Password</button>
            <Link to="/" style={{ textDecoration: "none" }}>
              <p className="forgot">
                Back to Login?{" "}
                <span style={{ color: "#113E82" }} className="forgot">
                  Login
                </span>
              </p>
            </Link>
            {/* <div className="google"><img style={{transform: 'translateY(7px)', marginRight: '8px'}} src={Google} alt="" /><span>Sign in with google</span></div> */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetForm;
