import React, { useState } from 'react';
import './dashboard.scss';
import Sidebar from '../../components/sidebar/sidebar';
import { BrowserRouter, Route, Switch, useRouteMatch } from 'react-router-dom';
import DashboardLanding from '../../components/dashboard-components/dashboardLanding';
import Header from '../../components/header/header';
import Request from '../../components/request-components/request';
import Customers from '../../components/customers-components/customers';
import Trips from '../../components/trip-components/trips';
import Wallet from '../../components/wallet-components/wallet';
import Settings from '../settings/settings';
import Notifications from '../../components/notifications/notifications';
import LiveTracking from '../../components/live-tracking/liveTracking';
import SidebarCopy from '../../components/sidebarCopy/sidebarCopy';
import AutoComplete from '../../components/autoComplete/autocomplete';
import Ticket from '../ticket/ticket';
import TicketView from '../ticket/ticketView/ticketView';
import UnverifiedBanner from '../../components/unVerifiedBanner/unverifiedBanner';
import { useSelector } from 'react-redux'
import BetaBanner from '../unVerifiedBanner/unverifiedBanner';
import ManagePassword from '../managePassword/managePassword';
import ChatPage from '../chat/chat';
import ChatList from '../chatList/chatList';
import Promotions from '../promotions/promotions';
import {baseUrl} from "../../config.json";
import axios from 'axios';
import { toast } from 'react-toastify';

const config = {
    headers: {
       "Authorization": `Bearer ${localStorage.WEHAUL_CUSTOMER_TOKEN}`
       }
}

const Dashboard = () => {
    const { path, url }= useRouteMatch();

    const [navOpen, setNavOpen] = useState(true);
    const [mobileNavOpen, setMobileNavOpen] = useState(false)

    const [veriBanner, setVeriBanner] = useState(false);
    const [loading2, setLoading2] = useState(false)

    const currentUser = useSelector(({userData}) => userData.currentUser)
    console.log(currentUser?.data)

    const {name, walletBalance, phoneNumber, emailVerified, email, emailVerificationToken, isPhoneVerified} = currentUser?.data


    // const navState = useSelector(({uiState}) => uiState.state)
    // console.log('hi', navState);

    const dataxy = {
        email: email,
        userClass: "customer"
      }

    const resend = async (e) => {
        setLoading2(true)
        try {
            const {data} = await axios.post(`${baseUrl}/account/email-verification`, dataxy, config)
            console.log(data)
            setLoading2(false)
            toast.success(data.message)
        } catch (error) {
            toast.error(error?.response?.data?.message)
            setLoading2(false)
        }
      } 
    
    return (   
        <div className="dashboard">
            <div className="left">
               <Sidebar navOpen={navOpen} />
               {mobileNavOpen && <SidebarCopy setMobileNavOpen={setMobileNavOpen} />}
            </div>

            <div className="right" id={navOpen ? 'reduce' : 'full'}>

                <div className="header">
                    <Header setMobileNavOpen={setMobileNavOpen} setNavOpen={setNavOpen} navOpen={navOpen} />
                </div>
                {/* {!isPhoneVerified && <UnverifiedBanner phone={phoneNumber} name={name} />} */}
                {/* <BetaBanner /> */}
                {!emailVerified && <div className='ebanner'><p>Your Email has not been verified, please check your mail to verify before you can start receiving requests.</p><br /><p>Not received any verification mail? <span onClick={resend}>{loading2 ? "Wait..." : "Resend"}</span></p></div>}

                <Switch>
                   <Route exact path={path} component={DashboardLanding} />
                   <Route excat path={`${path}/request`} component={Request} />
                   <Route exact path={`${path}/customers`} component={Customers} />
                   <Route exact path={`${path}/trips`} component={Trips} />
                   <Route exact path={`${path}/finance`} component={Wallet} />
                   <Route exact path={`${path}/chats`} component={ChatList} />
                   <Route exact path={`${path}/settings`} component={Settings} />
                   <Route exact path={`${path}/notifications`} component={Notifications} />
                   <Route exact path={`${path}/live-tracking`} component={LiveTracking} />
                   <Route exact path={`${path}/promotions`} component={Promotions} />
                   {/* <Route exact path={`${path}/test`} component={AutoComplete} /> */}
                   <Route exact path={`${path}/ticket`} component={Ticket} />
                   <Route exact path={`${path}/manage-password`} component={ManagePassword} />
                   <Route exact path={`${path}/ticket/:ticketId`} component={TicketView} />
                   <Route exact path={`${path}/dashboard/chat/:chatId`} component={ChatPage} />
                </Switch>

            </div>
        </div> 
    );
};

export default Dashboard;