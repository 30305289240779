import React from 'react';
import { useSelector } from 'react-redux';
import PubNub from "pubnub";
import { PubNubProvider } from "pubnub-react";
import './chat.scss';
import {
  Chat,
  MessageList,
  MessageInput,
  TypingIndicator,
  ChannelList,
  MemberList,
} from "@pubnub/react-chat-components";




const ChatPage = (props) => {
    const currentUser = useSelector(({userData}) => userData.currentUser)
    console.log(currentUser?.data);

    console.log(props);
    const channelConnector = props.location.state.chatRecipient
    const namex = props.location.state.name
    const picture = props.location.state.picture

    const {name, _id: id} = currentUser?.data

    const pubnub = new PubNub({
        publishKey: "pub-c-bbede6b7-ff03-41f1-a569-cdf065a85199",
        subscribeKey: "sub-c-cc785b06-b9ac-11ec-9873-d29fac035801",
        uuid: name,
      });
      const currentChannel = `${id}` + channelConnector;
      const theme = "light";
    return (
        <div className='chat'>
            {/* <h1>{`${id}` + `${channelConnector}`}</h1> */}

            <div className="chatter">
                <div style={{backgroundImage: `url(${picture})`, backgroundSize: 'cover'}} className="img"></div>
                <p className="name">{namex}</p>
            </div>

            <PubNubProvider client={pubnub}>
                <Chat {...{ currentChannel, theme }}>
                    <MessageList>
                        <TypingIndicator />
                    </MessageList>
                    <MessageInput />
                </Chat>
            </PubNubProvider>
        </div>
    )
}

export default ChatPage;
