import React from 'react';
import './sidebar.scss';
import Logo from '../../assets/wehaulogo2.png';
import { NavLink, useLocation } from 'react-router-dom'
import { Img } from '@chakra-ui/react';

const Sidebar = ({navOpen}) => {
    const { pathname } = useLocation();
    return (
        <div className="side-bar" id={navOpen ? 'enter' : 'collapse'}>
            <div className="logo">
                <Img margin="0 auto" src={Logo} alt="" />
            </div>

            <div className="nav">
                <NavLink activeClassName='active' className='list-item' exact to='/dashboard'><i className="fas fa-home"></i><span className='label'>Dashboard</span></NavLink>
                <NavLink isActive={() => ['/dashboard/request/make-request', '/dashboard/request/drivers', '/dashboard/request'].includes(pathname)} activeClassName='active' className='list-item' exact to='/dashboard/request'><i className="fas fa-chalkboard-teacher"></i><span className='label'>Request</span></NavLink>
                {/* <NavLink activeClassName='active' className='list-item' exact to='/dashboard/customers'><i className="fas fa-users"></i><span className='label'>Customers</span></NavLink> */}
                <NavLink isActive={() => ['/dashboard/trips', '/dashboard/trips/tripId'].includes(pathname)} activeClassName='active' className='list-item' exact to='/dashboard/trips'><i style={{marginLeft: '3px', marginRight: '23px'}} className="fas fa-map-marker-alt"></i><span className='label'>Trips</span></NavLink>
                {/* <NavLink activeClassName='active' className='list-item' exact to='/dashboard/payments'><i style={{marginRight: '23px'}} className="fas fa-wallet"></i><span className='label'>Finance</span></NavLink> */}
                {/* <NavLink activeClassName='active' className='list-item' exact to='/dashboard/chats'><i style={{marginRight: '23px'}} className="far fa-comment-dots"></i><span className='label'>Chat</span></NavLink> */}
                <NavLink activeClassName='active' className='list-item' exact to='/dashboard/notifications'><i style={{marginRight: '23px'}} className="far fa-bell"></i><span className='label'>Notifications</span></NavLink>
                <NavLink activeClassName='active' className='list-item' exact to='/dashboard/live-tracking'><i style={{marginRight: '23px'}} className="fas fa-location-arrow"></i><span className='label'>Live Tracking</span></NavLink>
                {/* <NavLink activeClassName='active' className='list-item' exact to='/dashboard/ticket'><i style={{marginRight: '23px'}} className="fas fa-headset"></i><span className='label'>Support</span></NavLink> */}
                <NavLink activeClassName='active' className='list-item' exact to='/dashboard/promotions'><i style={{marginRight: '23px'}} className="fas fa-list"></i><span className='label'>Promotions</span></NavLink>
                <NavLink activeClassName='active' className='list-item' exact to='/dashboard/settings'><i style={{marginRight: '23px'}} className="fas fa-cog"></i><span className='label'>Settings</span></NavLink>
            </div>
           
        </div>
    )
}

export default Sidebar;