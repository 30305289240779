import React, { useState } from 'react';
import './requestList.scss';
import { useHistory, Link } from 'react-router-dom';
import AllRequestdata from '../../../static/allRequest';
import AllRequests from './all-requests/allRequests';
import ActiveRequests from './active-requests/activeRequests';
import CancelledRequests from './cancelled-requests/cancelledRequest';
import { useQuery } from 'react-query';
import { getAllRequests } from '../../../services/requestServices'
import Loader from '../../loader/loader';

const RequestList = (props) => {
    const [page, setPage] = useState('all');
    const history = useHistory();

    const { data, error, isLoading} = useQuery('getRequests', getAllRequests);
    if(isLoading) {
        console.log('data is loading')
    }else if(data) {
        console.log('getRequests', data.data.data)
    } else {
        console.log(error)
    }
    

    return ( 
        <div className="request-list">
            <div className="heading">
                <div className="left-items">
                {/* <i onClick={() => window.location.href = '/dashboard'} className="fas fa-home"></i> */}
                    <div className="title"><span>Requests</span></div>
                    <p className="request-count">{data?.data?.data.filter(i => i.requestStatus === "active").length}</p>
                    {/* <p className="export-btn">Export</p> */}
                </div>
                <div className="right-items">
                <Link style={{textDecoration:'none'}} to='/dashboard/request/make-request'><p className='add-action'><i className="fas fa-plus"></i>Request New Trip</p></Link>
                </div>
            </div>

            <div className="nav">
                <p onClick={() => setPage('all')} className={page === 'all' ? 'active-all nav-items' : 'nav-items'}>ALL REQUESTS</p>
                <p onClick={() => setPage('active')} className={page === 'active' ? 'active-active nav-items' : 'nav-items'}>ACTIVE REQUESTS</p>
                <p onClick={() => setPage('cancelled')} className={page === 'cancelled' ? 'active-cancelled nav-items' : 'nav-items'}>CANCELLED REQUESTS</p>
            </div>

            {isLoading ? <Loader /> : page === 'all' && <AllRequests />}
            {isLoading ? <Loader /> : page === 'active' && <ActiveRequests />}
            {isLoading ? <Loader /> : page === 'cancelled' && <CancelledRequests />}

        </div>
     );
}
 
export default RequestList;