import { baseUrl } from '../config.json';
import axios from 'axios';
import { useSelector } from 'react-redux'; 

const config = {
    headers: {
       "Authorization": `Bearer ${localStorage.WEHAUL_CUSTOMER_TOKEN}`
       }
 }

export async function getPayOutRequests() {
    return axios.get(`${baseUrl}/customer/retrieve-payout-requests`, config)
}
export async function getTripPayments() {
    return axios.get(`${baseUrl}/customer/retrieve-trip-payments`, config)
}
export async function getWalletFunding() {
    return axios.get(`${baseUrl}/customer/retrieve-wallet-funding`, config)
}