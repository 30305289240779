import React, { useState } from "react";
import "./login.scss";
import Google from './googleIcon.svg';
import { signin } from '../../redux/actions/auth';
import { useHistory, Redirect, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as routes from '../../routing/route';
import { connect } from 'react-redux'
import ButtonLoader from "../../components/buttonLoader/buttonLoader";
import Logo from '../../assets/logo.svg';
import {baseUrl} from '../../config.json';
import { userActionTypes } from "../../redux/constants/userActionTypes";
import axios from "axios";
import { toast } from "react-toastify";
import {GoogleLogin,GoogleLogout} from "react-google-login";
import { Button, Img, Text } from "@chakra-ui/react";

const Login = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  

  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { isAuthenticated, currentUser} = props;
  const formData = {
    email,
    password
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
     try {
        const { data } = await axios.post(`${baseUrl}/auth/customer/login`, formData);
        console.log(data, data.token)
        setLoading(false)
        localStorage.setItem("WEHAUL_CUSTOMER_TOKEN", data.token);
        dispatch({ type: userActionTypes.SIGNIN_SUCCESS, payload: data });
        window.location.reload();
     } catch (error) {
        console.log(error?.response?.data?.message)
        toast.error(error?.response?.data?.message, {
           position: toast.POSITION.TOP_CENTER
        })
        setLoading(false)
     }
  }

  if(isAuthenticated && currentUser){
  return <Redirect to={routes.DashBoard}/>
  }

  

  const googleResponse = async (response) => {
    console.log(response);
    const datax = {
      "idToken": response?.tokenId,
      "userClass": "customer"
    }
    try {
      const res = await axios.post(`${baseUrl}/auth/loginWithGoogle`, datax)
      console.log(res)
      toast.success(res?.data?.message)
      await localStorage.setItem("WEHAUL_CUSTOMER_TOKEN", res?.data?.token);
      // dispatch({ type: userActionTypes.SIGNIN_SUCCESS, payload: res?.data?.data });
      window.location.reload();
    } catch(error) {
      toast.error(error?.response?.data?.message)
      console.log(error?.response?.data?.message)
    }
  }

  const logout = (response) => {
    console.log(response);
  }

  return (
    <div className="login">
      <div className="container">
        <div className="left"></div>
        <div className="right">
          <form onSubmit={handleSubmit}>
          <img id="logo" src={Logo} alt="wehaulogo" />
          <h3>Customer Sign in</h3>
            <input
              type="email"
              placeholder="Email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />{" "}
            <br />
            <div className="p-word">
              <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
              {showPassword && <i onClick={() => setShowPassword(prev => !prev)} className="fas fa-eye"></i>}
              {!showPassword && <i onClick={() => setShowPassword(prev => !prev)} className="fas fa-eye-slash"></i>}
            </div>
            
            <button className="button" type='submit'>{loading && <ButtonLoader />} Sign in</button>
            <Link to="/reset-password-mail" style={{textDecoration: "none"}}><p className="forgot">Forgot password ?</p></Link>
            <Link to="/signup" style={{textDecoration: "none"}}><p className="forgot">Don't have an account? <span style={{color: "#113E82"}} className="forgot">Sign up</span></p></Link>
            <Text mt={5}>Or</Text>
            {/* <div className="google"><img style={{transform: 'translateY(7px)', marginRight: '8px'}} src={Google} alt="" /><span>Sign in with google</span></div> */}
            <GoogleLogin
              clientId="570006213811-5vlu8tgn6n9hcd6m12a3le9o07ogk1kq.apps.googleusercontent.com"
              render={renderProps => (
                <Button onClick={renderProps.onClick} mt={5} width="100%" variant="outline" height={47}><Img src={Google} />Sign in with google</Button>
                // <button className="google"  disabled={renderProps.disabled}>This is my custom Google button</button>
                // <div onClick={renderProps.onClick} className="google"><Img style={{transform: 'translateY(7px)', marginRight: '8px'}} src={Google} alt="" /><span>Sign in with google</span></div>
                )}
              buttonText="Login"
              onSuccess={googleResponse}
              onFailure={googleResponse}
              cookiePolicy={'single_host_origin'}
              prompt="consent"
            />

          </form>
          
          
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({userData})=> ({
  isAuthenticated: userData.isAuthenticated,
  currentUser: userData.currentUser
})

export default connect(mapStateToProps, null)(Login);
