import React from "react";
import "./payment.scss";
import { PaystackButton } from "react-paystack";
import { toast } from "react-toastify";
import axios from "axios";
import { baseUrl } from "../../config.json";
import { useSelector } from "react-redux";

const Payment = ({
  userEmail,
  userId,
  depositAmount,
  driverId,
  userClass,
  reqId,
}) => {
  const config = {
    referenceDate: new Date().getTime().toString(),
    email: userEmail,
    amount: depositAmount * 100,
    metadata: {
      userClass: "customer",
      _id: userId,
    },
    // publicKey: "pk_test_92e1fe3a76a003475f4a9b1b868c2a1f27e3c7b3",
    publicKey: "pk_live_188c18c736ea5f25357fe4414665d6e8fe16896e",
  };

  const currentUser = useSelector(({ userData }) => userData.currentUser);

  const { discount } = currentUser.data;

  const onSuccess = async (reference) => {
    console.log("transaction successful", reference);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.WEHAUL_CUSTOMER_TOKEN}`,
      },
      body: JSON.stringify({
        transRef: reference.reference,
        userClass,
        isPromoUsed: discount?.promoStatus,
        feeWithCommission: depositAmount,
      }),
    };
    try {
      await fetch(
        `${baseUrl}/customer/request/${reqId}/select-driver/${driverId}`,
        requestOptions
      )
        .then((res) => res.json())
        .then((res) =>
          toast.success(res.message, {
            position: toast.POSITION.TOP_CENTER,
          })
        )
        .then((res) => (window.location.href = "/dashboard/trips"));
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const onClose = () => {
    toast.error("transaction not completed", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const componentProps = {
    ...config,
    text: "CONTINUE",
    className: "button",
    onSuccess: (reference) => onSuccess(reference),
    onClose: onClose,
  };

  return (
    <div className="payment">
      <PaystackButton {...componentProps} />
    </div>
  );
};

export default Payment;
