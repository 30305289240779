import React, { useState, useEffect } from 'react';
import './availableDriversX.scss';
import Illustration from '../available-drivers/illustration.svg';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { baseUrl } from '../../../config.json';
import TLoader from '../../tLoader/tloader';
import { useHistory } from 'react-router';
import DeleteModal from '../../deleteModal/deleteModal';

const AvailableDriversX = (props) => {
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(true);
    const [drivers, setDrivers] = useState([]);
    
    // const [drivers, setDrivers] = useState([]);
    console.log(props)
    const history = useHistory();

    const reqId = props.location.state.reqId
    const reqData = props.location.state.reqData

    // if(localStorage.BIKE_DRIVERS_DATA) {
        // const bd = JSON.parse(localStorage.BIKE_DRIVERS_DATA)
        // console.log(bd)
    // }
    

    const config = {
        headers: {
           "Authorization": `Bearer ${localStorage.WEHAUL_CUSTOMER_TOKEN}`
           }
    }

    useEffect(() => {
        (async () => {
          const { data } = await axios.get(
            `${baseUrl}/customer/request/${reqId}/request-details`, config
          );
          console.log(data);
          setDrivers(data.data.qualifiedBikeRiders);
          setLoading2(false);
        })();
      }, []);

    const selectDriver = async (userClass, id, routeWithPrice) => {
        console.log('bingo', userClass, id, routeWithPrice);
        setLoading(true);

        const formdata = {
            "price": routeWithPrice.price,
            "userClass": userClass
        }
    
        try {
          const { data } = await axios.put(`https://wehaul-nig.herokuapp.com/api/v1/customer/request/${reqId}/select-bike-rider/${id}`, formdata, config)
          console.log(data)
          toast.success(data.message, {
            position: toast.POSITION.TOP_CENTER
          })
          setLoading(false)
          localStorage.setItem('asibikdri', id)
          history.push('/dashboard/request')
        } catch (error) {
          console.log(error?.response?.data?.message)
          setLoading(false)
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_CENTER
          })
        }
    }

    return ( 
        <div className="available-drivers">
            {loading2 && <TLoader />}
            <div className="top-sec">
                <div className="leftx">
                    <Link to='/dashboard/request'><i className="fas fa-arrow-left"></i></Link>
                    <p className="title">Available Drivers</p>
                </div>
                <div className="rightx">
                    <img src={Illustration} alt="illustration" />
                </div>
            </div>

            <div className="drivers">
                {drivers?.map(({ name, price, routeWithPrice, avgRating, profilePicture, userClass, _id: id }, index) => {
                    // const filtered = qualifiedDrivers?.filter(d => d.driver === idx).map(item => item.price)
                    // console.log(filtered)
                    return (
                        <div className="item" key={index}>
                            <div className="item-left">
                                <div className="driver-img" style={{backgroundImage: `url(${profilePicture})`}}></div>
                                <div className="name-rating-vt">
                                    <p className="name">{name}</p>
                                    {avgRating === 1 && <span className='rating'><i className="fas fa-star"></i>{avgRating.toFixed(1)}</span>}
                                    {avgRating === 2 && <span className='rating'><i className="fas fa-star"></i><i className="fas fa-star"></i>{avgRating.toFixed(1)}</span>}
                                    {avgRating === 3 && <span className='rating'><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i>{avgRating.toFixed(1)}</span>}
                                    {avgRating === 4 && <span className='rating'><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i>{avgRating.toFixed(1)}</span>}
                                    {avgRating === 5 && <span className='rating'><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i>{avgRating.toFixed(1)}</span>}
                                    <span className='vt'>Bike</span>
                                </div>
                                <p className="price">N{routeWithPrice.price.toLocaleString()}</p>
                            </div>
                            <div className="item-right">
                                {id !== localStorage.asibikdri && <button onClick={() => selectDriver(userClass, id, routeWithPrice)}><i className="fas fa-user-check"></i>Assign</button>}
                                {/* <i id='close' className="fas fa-times"></i> */}
                            </div>
                        </div>
                    )
                })}
            </div>
            <DeleteModal />
        </div>
     );
}
 
export default AvailableDriversX;