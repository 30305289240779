import React from "react";
import { useQuery } from "react-query";
import './chatList.scss';
import EmptyState from "../../components/emptyState/emptyState";
import { getAllTrips } from "../../services/tripServices";
import LineLoader from "../../components/lineLoader/lineLoader";
import { useHistory } from "react-router";

const ChatList = () => {
    const history = useHistory()

    const { data, error, isLoading } = useQuery('getAllTrips', getAllTrips)
    if(isLoading) {
        console.log('loading all trips')
    } else if (data) {
        console.log('get all trips', data.data.data)
    } else {
        console.log(error)
    }

    const handleChatRedirect = (id, name, picture) => {
        console.log(id)
        history.push(`dashboard/chat/${id}`, {chatRecipient: id, name, picture})
    }
    
  return (
    <div className="chat-list">
        { isLoading ? <LineLoader /> :
        data.length === 0 ? <EmptyState /> : 
          data?.data?.data.filter(el => el.tripStatus !== "completed").map(({acceptedBy, request, tripFee, _id: id}) => {
              return (
                  <div onClick={() => handleChatRedirect(id, acceptedBy?.name, acceptedBy?.profilePicture)} className="chat-item">
                      <div className="left">
                        <div style={{backgroundImage: `url(${acceptedBy?.profilePicture})`, backgroundSize: 'cover'}} className="img"></div>
                        <div>
                        <p className="name">{acceptedBy?.name}</p>
                        <p className="trip">{request?.pickUpAddress} to {request?.destinationAddress}</p>
                        </div>
                      </div>
                      {/* <div className="right">
                        <button onClick={() => handleChatRedirect(acceptedBy.id, acceptedBy.name, acceptedBy.profilePicture)} className="accept"><i style={{color: "#00C46F", marginRight: "5px"}} className="fas fa-comment-dots"></i>chat</button>
                      </div> */}
                  </div>
              )
          })
        }
    </div>
  );
};

export default ChatList;
