import React, {useState} from 'react';
import './whatsappPay.scss';
import Logo from '../../assets/logo.svg';
import Doodle from '../../assets/doodle.png';
import { PaystackButton } from "react-paystack";
import axios from 'axios';
import {baseUrl} from '../../config.json';

import { toast } from 'react-toastify';

const WhatsappPay = () => {
    const [email, setEmail] = useState('');
    const [amount, setAmount] = useState('');
    const [loading, setLoading] = useState(false);

    const config = {
        referenceDate: new Date().getTime().toString(),
        email,
        amount: amount * 100,
        // metadata: {
        //   userClass: "customer",
        //   _id: userId,
        // },
        publicKey: "pk_test_92e1fe3a76a003475f4a9b1b868c2a1f27e3c7b3",
        // publicKey: "pk_live_188c18c736ea5f25357fe4414665d6e8fe16896e",
      };

      const handleDefault = e => {
          e.preventDefault()
      }
    
      const onSuccess = async (reference) => {
        console.log("transaction successful", reference);
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
        };
        // try {
        //   await fetch(
        //     `${baseUrl}/wallet/fund/${reference.reference}`,
        //     requestOptions
        //   )
        //     .then((res) => res.json())
        //     .then((res) =>
        //       toast.success(res.message, {
        //         position: toast.POSITION.TOP_CENTER,
        //       })
        //     )
        //   .then(res => window.location.reload())
        // } catch (error) {
        //   toast.error(error?.response?.data?.message, {
        //     position: toast.POSITION.TOP_CENTER,
        //   });
        // }
      };
    
      const onClose = () => {
        toast.error("transaction not completed", {
          position: toast.POSITION.TOP_CENTER,
        });
      };
    
      const componentProps = {
        ...config,
        text: "Pay Now",
        className: "button",
        onSuccess: (reference) => onSuccess(reference),
        onClose: onClose,
      };

    return (
        <div style={{backgroundImage: `url(${Doodle})`}} className="whatsapp-pay">
            <form onSubmit={handleDefault}>
                <img id="logo" src={Logo} alt="wehaulogo" /><br />
                <input type="text" placeholder="Enter your email address linked to wehaul" onChange={e => setEmail(e.target.value)} /><br />
                <input type="tel" placeholder="Enter Amount" onChange={e => setAmount(e.target.value)}/><br />
                <PaystackButton {...componentProps} />
            </form>
        </div>
    )
}

export default WhatsappPay
